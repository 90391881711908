import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  Building2, 
  Phone, 
  Mail, 
  MapPin,
  Home,
  Key,
  Users,
  ClipboardCheck,
  ChevronRight,
  ArrowRight,
  Star,
  X,
  Laptop,
  Headset,
  PoundSterling,
  Quote,
  ChevronDown,
  ChevronLeft,
  User2,
  Award,
  UserCheck,
  Check,
  Copy,
  ChevronUp
} from 'lucide-react';

const PropertyWebsite = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeService, setActiveService] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [hoveredLink, setHoveredLink] = useState(null);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  const [openDropdown, setOpenDropdown] = useState(false);
  const [copiedEmail, setCopiedEmail] = useState(false);
  const [copiedPhone, setCopiedPhone] = useState(false);
  
  
  const contactInfo = {
    email: "info@novalimited.co.uk",
    phone: "0749 503 1435"
  };

  const copyToClipboard = async (text, type) => {
    try {
      await navigator.clipboard.writeText(text);
      if (type === 'email') {
        setCopiedEmail(true);
        setTimeout(() => setCopiedEmail(false), 2000);
      } else {
        setCopiedPhone(true);
        setTimeout(() => setCopiedPhone(false), 2000);
      }
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };


  // Handle scroll events
  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 150);
      
      // Update active section based on scroll position
      const sections = document.querySelectorAll('section[id]');
      const scrollY = window.scrollY;

      sections.forEach(section => {
        const sectionTop = section.offsetTop - 100;
        const sectionHeight = section.offsetHeight;
        const sectionId = section.getAttribute('id');

        if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
          setActiveSection(sectionId);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  
  const triggerNotification = (message) => {
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 3000);
  };

  const properties = [
    { 
      title: "The Glasshouse",
      type: "Residential Block",
      location: "Arthur Rd",
      desc: "Elegant 4-bedroom residence offering dual bathrooms and versatile living areas in sought-after location",
      stats: {
        units: "124 Units",
        occupancy: "98%",
        managed: "Since 2020"
      },
      features: ["24/7 Concierge", "Gym & Spa", "Secure Parking", "Riverside Gardens"],
      img: "glasshouse/glasshouse.jpg",
      propertyType: "residential",
      status: "Fully Managed"
    },
    {
      title: "Madinah Mansions",
      type: "Commercial Complex",
      location: "Vauxhall Bridge Rd",
      desc: "Contemporary residence featuring secluded outdoor retreat, expansive corridors and abundant natural light throughout",
      stats: {
        bedrooms: "3 large",
        occupancy: "95%",
        managed: "Since 2019"
      },
      features: ["24/7 Security", "Meeting Rooms", "Parking", "On-site Café"],
      img: "madinah-mansions/madinah-mansions.jpg",
      propertyType: "commercial",
      status: "Block Management"
    },
    {
      title: "Al Hamra Mansions",
      type: "Mixed Use",
      location: "Mitcham Road",
      desc: "Prestigious new development featuring light-filled residences, bespoke marble finishes and spacious contemporary layouts",
      stats: {
        units: "86 Units",
        occupancy: "97%",
        managed: "Since 2021"
      },
      features: ["Period Features", "Courtyard Garden", "Secure Entry", "Retail Space"],
      img: "al-hamra-mansions/al-hamra-mansions.jpg",
      propertyType: "mixed",
      status: "Full Management"
    }
  ];
  const services = [
    {
      icon: Home,
      title: "Property Management",
      desc: "Complete management services for your property portfolio",
      features: ["24/7 Support", "Online Portal", "Regular Inspections"]
    },
    {
      icon: Key,
      title: "Lettings",
      desc: "Professional letting services for landlords and tenants", 
      features: ["Tenant Screening", "Contract Management", "Rent Collection"]
    },
    {
      icon: Users,
      title: "Portfolio Management",
      desc: "Specialised services for residential blocks and estates",
      features: ["Communal Maintenance", "Service Charge", "Building Insurance"] 
    },
    {
      icon: ClipboardCheck,
      title: "Maintenance",
      desc: "Regular maintenance and emergency repairs",
      features: ["Emergency Response", "Planned Maintenance", "Quality Control"]
    }
  ];


const [activeIndex, setActiveIndex] = useState(0);


const testimonials = [
  {
    text: "Nova Asset Management has transformed how we manage our residential portfolio. Their proactive maintenance approach has reduced our operational costs by 23% while improving tenant satisfaction. The online portal makes tracking property performance incredibly straightforward.",
    author: "Sarah Thompson",
    position: "Property Portfolio Manager",
    company: "London Residential Investments",
    rating: 5,
    propertyType: "Multi-unit Residential",
    duration: "Client since 2021",
    portfolio: "85 units managed",
    image: "blank-profile-picture-973460_1280.webp"
  },
  {
    text: "The level of professionalism and attention to detail from Nova Asset Management is exceptional. They've streamlined our service charge management and improved communication with residents. Their block management expertise has been invaluable for our development.",
    author: "Michael Stewart",
    position: "Director",
    company: "Highland Developments Ltd",
    rating: 5,
    propertyType: "Mixed-use Development",
    duration: "Client since 2020",
    portfolio: "3 blocks, 142 units",
    image: "blank-profile-picture-973460_1280.webp"
  },
  {
    text: "Since switching to Nova Asset Management, our property's occupancy rates have increased to 98%. Their tenant screening process is thorough, and their maintenance response times are impressive. The quarterly reports provide excellent insights for our investors.",
    author: "Emma Blackwood",
    position: "Investment Manager",
    company: "Crown Property Group",
    rating: 5,
    propertyType: "Commercial Properties",
    duration: "Client since 2019",
    portfolio: "12 commercial units",
    image: "blank-profile-picture-973460_1280.webp"
  },
  {
    text: "The transition to Nova Asset Management's management services was seamless. Their team's expertise in handling our mixed-use development has resulted in significant improvements in both tenant satisfaction and operational efficiency.",
    author: "James Richardson",
    position: "Development Director",
    company: "Urban Living Spaces",
    rating: 5,
    propertyType: "Mixed-use Development",
    duration: "Client since 2022",
    portfolio: "4 developments, 230 units",
    image: "blank-profile-picture-973460_1280.webp"
  },
  {
    text: "Nova Asset Management's block management service has exceeded our expectations. Their transparent communication and efficient handling of maintenance issues has greatly improved our residents' satisfaction levels.",
    author: "Victoria Chen",
    position: "Board Chairman",
    company: "The Riverside Apartments",
    rating: 5,
    propertyType: "Residential Block",
    duration: "Client since 2020",
    portfolio: "1 block, 156 units",
    image: "blank-profile-picture-973460_1280.webp"
  }
];


const [isHidden, setIsHidden] = useState(false);
const [lastScrollY, setLastScrollY] = useState(0);

useEffect(() => {
  const controlHeader = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > lastScrollY && currentScrollY > 100) {
      setIsHidden(true);
    } else {
      setIsHidden(false);
    }
    
    setLastScrollY(currentScrollY);
  };

  window.addEventListener('scroll', controlHeader);
  return () => window.removeEventListener('scroll', controlHeader);
}, [lastScrollY]);

const location = useLocation();

  return (
    <div className="min-h-screen bg-white">
      
      {/* Notification */}
      <div className={`fixed top-4 right-4 transform transition-all duration-300 z-50 ${showNotification ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'}`}>
        <div className="bg-[#b2a5a1] text-white px-6 py-3 rounded-lg shadow-lg flex items-center">
          <span>Thanks for your interest! We'll be in touch soon.</span>
          <button onClick={() => setShowNotification(false)} className="ml-3 hover:text-neutral-200">
            <X size={18} />
          </button>
        </div>
      </div>

      {/* Scroll to top button */}
      <button
        onClick={scrollToTop}
        className={`fixed bottom-8 right-8 z-40 p-3 bg-[#b2a5a1] text-white rounded-full shadow-lg transition-all duration-300 hover:bg-[#8c7f7c] ${
          showScrollTop ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-16'
        }`}
      >
        <ChevronUp size={24} />
      </button>

{/* Header */}
<header 
  className={`
    bg-white border-b border-[#eae7e6] sticky top-0 z-40 
    transition-transform duration-300 
    ${isHidden ? '-translate-y-full' : 'translate-y-0'}
  `}
>    
  <div className="max-w-7xl mx-auto px-4 sm:px-6">
    <div className="flex justify-between items-center h-20">
      {/* Logo */}
      <div className="w-64"> 
        <Link to="/" className="flex items-center group cursor-pointer">
          <img 
            src="/header-logo-new.png" 
            alt="Nova Asset Management" 
            className="h-12 md:h-14 w-auto object-contain md:object-fill transform group-hover:scale-105 transition-transform duration-200"
          />
        </Link>
      </div>

      {/* Centered Desktop Navigation */}
      <nav className="hidden md:flex flex-1 justify-center">
        <div className="flex space-x-12">
          {["Home", "About", "Services", "Properties", "Contact"].map((item) => (
            <div
              key={item}
              className="relative group"
              onMouseEnter={() => {
                setHoveredLink(item);
                if (item === "Services") setOpenDropdown(true);
              }}
              onMouseLeave={() => {
                setHoveredLink(null);
                if (item === "Services") setOpenDropdown(false);
              }}
            >
              <Link
                to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
                className={`
                  relative text-[#8c7f7c] hover:text-[#b2a5a1] font-medium 
                  transition-colors duration-200 py-2 flex items-center 
                  ${(item === "Home" && location.pathname === "/") || 
                    (item !== "Home" && location.pathname === `/${item.toLowerCase()}`)
                      ? "text-[#b2a5a1]" 
                      : ""
                  }
                `}
              >
                {item}
                {item === "Services" && (
                  <ChevronDown className="ml-1 w-4 h-4" />
                )}
                <span 
                  className={`
                    absolute bottom-0 left-0 w-full h-0.5 bg-[#b2a5a1] 
                    transform origin-left transition-transform duration-200 
                    ${((item === "Home" && location.pathname === "/") || 
                       (item !== "Home" && location.pathname === `/${item.toLowerCase()}`) ||
                       hoveredLink === item) ? 'scale-x-100' : 'scale-x-0'
                    }
                  `}
                />
              </Link>

              {/* Services Dropdown */}
              {item === "Services" && (
                <div className={`
                  absolute left-1/2 transform -translate-x-1/2 mt-2 w-72 
                  bg-white rounded-lg shadow-lg border border-[#eae7e6] 
                  transition-all duration-200 
                  ${openDropdown ? 'opacity-100 visible translate-y-0' : 'opacity-0 invisible -translate-y-2'}
                `}>
                  <div className="p-4 space-y-3">
                    {[
                      { 
                        icon: Home, 
                        title: "Property Management",
                        sectionId: "property-management", 
                        subItems: [
                          { name: "Residential Lettings", id: "residential-lettings" },
                          { name: "Short Term Luxury Lettings", id: "short-term-luxury-lettings" },
                          { name: "Portfolio Management", id: "portfolio-management" }
                        ] 
                      },
                      { 
                        icon: Key, 
                        title: "Planning, Design & Build",
                        sectionId: "development", 
                        subItems: [
                          { name: "Planning", id: "planning" },
                          { name: "Design and Build", id: "design-and-build" }
                        ] 
                      }
                    ].map((service, index) => (
                      <div
                        key={index}
                        className="relative group/item"
                        onMouseEnter={() => setHoveredLink(service.title)}
                        onMouseLeave={() => setHoveredLink(null)}
                      >
                        <div 
                          className="flex items-center justify-between px-3 py-2 rounded-md hover:bg-[#f7f5f5] text-[#8c7f7c] hover:text-[#b2a5a1] transition-colors duration-200 cursor-pointer"
                          onClick={() => {
                            navigate(`/services#${service.sectionId}`);
                            setOpenDropdown(false);
                            setHoveredLink(null);
                          }}
                        >
                          <div className="flex items-center space-x-3 min-w-0">
                            <service.icon className="w-5 h-5 flex-shrink-0" />
                            <span className="font-medium whitespace-nowrap">{service.title}</span>
                          </div>
                          <ChevronDown className="w-4 h-4 flex-shrink-0 ml-3" />
                        </div>

                        {/* Nested Dropdown */}
                        <div className={`
                          absolute left-full top-0 w-56 bg-white rounded-lg 
                          shadow-lg border border-[#eae7e6] ml-2 transition-all duration-200
                          ${hoveredLink === service.title ? 'opacity-100 visible translate-x-0' : 'opacity-0 invisible -translate-x-2'}
                        `}>
                          <div className="py-2">
                            {service.subItems.map((subItem, subIndex) => (
                              <button
                                key={subIndex}
                                onClick={() => {
                                  navigate(`/services#${subItem.id}`);
                                  setOpenDropdown(false);
                                  setHoveredLink(null);
                                }}
                                className="w-full text-left px-4 py-2 text-[#8c7f7c] hover:text-[#b2a5a1] hover:bg-[#f7f5f5] text-sm transition-colors duration-200"
                              >
                                {subItem.name}
                              </button>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </nav>

      {/* Right Section - Contact Icons and Mobile Menu */}
      <div className="w-64 flex items-center justify-end">
        {/* Contact Icons - Desktop Only */}
        <div className="hidden md:flex items-center gap-4 mr-4">
          {/* Email Icon with Tooltip */}
          <div className="relative">
            <div className="p-2 group">
              <Mail 
                size={20} 
                className="text-[#8c7f7c] group-hover:text-[#b2a5a1] cursor-pointer"
              />
              {/* Email Tooltip */}
              <div className="absolute right-0 mt-2 w-auto min-w-max invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-200 bg-white border border-[#eae7e6] rounded-lg shadow-lg p-3 z-50">
                <div className="flex items-center space-x-2 whitespace-nowrap">
                  <span className="text-[#8c7f7c]">{contactInfo.email}</span>
                  <button 
                    onClick={(e) => {
                      e.stopPropagation();
                      copyToClipboard(contactInfo.email, 'email');
                    }}
                    className="text-[#8c7f7c] hover:text-[#b2a5a1] transition-colors duration-200 ml-2"
                  >
                    {copiedEmail ? <Check size={16} /> : <Copy size={16} />}
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Phone Icon with Tooltip */}
          <div className="relative">
            <div className="p-2 group">
              <Phone 
                size={20} 
                className="text-[#8c7f7c] group-hover:text-[#b2a5a1] cursor-pointer"
              />
              {/* Phone Tooltip */}
              <div className="absolute right-0 mt-2 w-auto min-w-max invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-200 bg-white border border-[#eae7e6] rounded-lg shadow-lg p-3 z-50">
                <div className="flex items-center space-x-2 whitespace-nowrap">
                  <span className="text-[#8c7f7c]">{contactInfo.phone}</span>
                  <button 
                    onClick={(e) => {
                      e.stopPropagation();
                      copyToClipboard(contactInfo.phone, 'phone');
                    }}
                    className="text-[#8c7f7c] hover:text-[#b2a5a1] transition-colors duration-200 ml-2"
                  >
                    {copiedPhone ? <Check size={16} /> : <Copy size={16} />}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile menu button */}
        <button 
          className="md:hidden relative group p-2"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <div className="flex flex-col space-y-1.5">
            <span className={`block w-6 h-0.5 bg-[#8c7f7c] transform transition-all duration-200 ${isMenuOpen ? 'rotate-45 translate-y-2' : ''}`}></span>
            <span className={`block w-6 h-0.5 bg-[#8c7f7c] transition-all duration-200 ${isMenuOpen ? 'opacity-0' : ''}`}></span>
            <span className={`block w-6 h-0.5 bg-[#8c7f7c] transform transition-all duration-200 ${isMenuOpen ? '-rotate-45 -translate-y-2' : ''}`}></span>
          </div>
        </button>
      </div>
    </div>
  </div>

{/* Mobile Navigation */}
<div className={`md:hidden bg-white overflow-hidden transition-all duration-300 ${isMenuOpen ? 'max-h-screen' : 'max-h-0'}`}>
  <div className="px-4 py-3 space-y-2">
    {["Home", "About", "Services", "Properties", "Contact"].map((item) => (
      <div key={item}>
        {item === "Services" ? (
          <div>
            <button
              className={`w-full px-4 py-3 text-center hover:text-[#b2a5a1] hover:bg-[#f7f5f5] rounded-lg transition-all duration-200 ${
                location.pathname === "/services" ? "text-[#b2a5a1] bg-[#f7f5f5]" : "text-[#8c7f7c]"
              }`}
              onClick={() => setOpenDropdown(!openDropdown)}
            >
              <div className="flex items-center justify-center">
                Services
                <ChevronDown 
                  className={`ml-1 w-4 h-4 transform transition-transform duration-200 ${
                    openDropdown ? 'rotate-180' : ''
                  }`} 
                />
              </div>
            </button>
            
            {/* Mobile Services Dropdown */}
            <div className={`overflow-hidden transition-all duration-200 ${openDropdown ? 'max-h-96' : 'max-h-0'}`}>
              <div className="py-2 px-4 space-y-2">
                {[
                  { 
                    icon: Home, 
                    title: "Property Management",
                    sectionId: "property-management",
                    subItems: [
                      { name: "Residential Lettings", id: "residential-lettings" },
                      { name: "Short Term Luxury Lettings", id: "short-term-luxury-lettings" },
                      { name: "Portfolio Management", id: "portfolio-management" }
                    ]
                  },
                  { 
                    icon: Key, 
                    title: "Planning, Design & Build",
                    sectionId: "development",
                    subItems: [
                      { name: "Planning", id: "planning" },
                      { name: "Design and Build", id: "design-and-build" }
                    ]
                  }
                ].map((service, index) => (
                  <div key={index} className="space-y-2">
                    <button
                      onClick={() => {
                        navigate(`/services#${service.sectionId}`);
                        setIsMenuOpen(false);
                        setOpenDropdown(false);
                      }}
                      className="w-full flex items-center justify-between px-4 py-2 rounded-md hover:bg-[#f7f5f5] text-[#8c7f7c] hover:text-[#b2a5a1] transition-colors duration-200"
                    >
                      <div className="flex items-center space-x-3">
                        <service.icon className="w-5 h-5" />
                        <span className="font-medium">{service.title}</span>
                      </div>
                    </button>
                      
                    {/* Sub-items */}
                    <div className="pl-11 space-y-1">
                      {service.subItems.map((subItem, subIndex) => (
                        <button
                          key={subIndex}
                          onClick={() => {
                            navigate(`/services#${subItem.id}`);
                            setIsMenuOpen(false);
                            setOpenDropdown(false);
                          }}
                          className="w-full text-left py-2 px-4 text-[#8c7f7c] hover:text-[#b2a5a1] hover:bg-[#f7f5f5] rounded-md text-sm transition-colors duration-200"
                        >
                          {subItem.name}
                        </button>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          ) : (
            <Link
              to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
              className={`block px-4 py-3 text-center hover:text-[#b2a5a1] hover:bg-[#f7f5f5] rounded-lg transition-all duration-200 ${
                (item === "Home" && location.pathname === "/") || 
                (item !== "Home" && location.pathname === `/${item.toLowerCase()}`)
                  ? "text-[#b2a5a1] bg-[#f7f5f5]"
                  : "text-[#8c7f7c]"
              }`}
              onClick={() => setIsMenuOpen(false)}
            >
              <div className="flex items-center justify-center">
                {item}
              </div>
            </Link>
          )}
        </div>
      ))}
    </div>
  </div>
</header>

{/* Hero Section */}
<div className="relative z-30 py-24 bg-gradient-to-br from-white to-[#f7f5f5]">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="max-w-3xl mx-auto text-center">
          	     <div className="mb-6">
        			<img 
          				src="/text-logo.png" 
          				alt="Nova Asset Management" 
          				className="h-32 w-auto object-contain mx-auto"
        			/>
      			</div>
            <p className="text-lg text-[#b2a5a1] mb-8">
              Nova Asset Management has rapidly emerged as a dynamic force in UK property asset management. Our journey focuses on optimising rental properties through a combination of long-term tenancies, short-term rentals, and luxury serviced lodgings. This targeted strategy ensures our clients receive the highest possible returns, establishing us as industry leaders.
            </p>
            <p className="text-lg text-[#b2a5a1] mb-8">
              We are committed to supporting our clients by providing advance rent paid through direct debit on the first of every month, ensuring every investment is secured with a guaranteed income. With our assets under management growing rapidly and approaching significant milestones, we continuously improve our service offering to take advantage of future opportunities.
            </p>
            <div className="text-xl font-semibold text-[#4f3b3a] mb-8">
              "Infallible Commitment, Superior Results"
            </div>
          </div>

      {/* Stats Section */}
      <div className="mt-16 grid grid-cols-2 gap-8 md:grid-cols-4">
            {[
              { value: "50+", label: "Years Experience Combined", icon: Award },
              { value: "500+", label: "Properties Managed", icon: Building2 },
              { value: "£800M+", label: "Portfolio Value", icon: UserCheck },
              { value: "24/7", label: "Support Available", icon: Headset }
            ].map((stat, i) => (
              <div 
                key={i}
                className="text-center transform hover:scale-105 transition-transform duration-200"
              >
                <div className="flex justify-center mb-4">
                  <div className="p-3 bg-[#f7f5f5] rounded-lg">
                    <stat.icon className="h-6 w-6 text-[#4f3b3a]" />
                  </div>
                </div>
                <div className="text-3xl font-bold text-[#4f3b3a] mb-2">{stat.value}</div>
                <div className="text-[#b2a5a1]">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      
      {/* About Section */}
      <div className="relative bg-gradient-to-br from-white to-[#f7f5f5] overflow-hidden">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 py-24 lg:py-32">
          <div className="grid lg:grid-cols-2 gap-12 items-center">
            <div className="text-left">
              <h2 className="text-5xl font-bold text-[#4f3b3a] mb-6">Nova Asset Management</h2>
              <p className="mt-6 text-xl text-[#b2a5a1] max-w-lg">
                Your trusted partner in property asset management. We offer comprehensive solutions for property owners, investors, and residential blocks.
              </p>
              <div className="mt-8 space-y-4">
                {[
                  { icon: Building2, title: "Professional Management", desc: "Expert handling of your property portfolio" },
                  { icon: ClipboardCheck, title: "Compliance Assured", desc: "Stay compliant with all regulations" },
                  { icon: Users, title: "24/7 Support", desc: "Round-the-clock property care" }
                ].map((item, i) => (
                  <div key={i} className="flex items-center">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-[#f7f5f5]">
                        <item.icon className="h-6 w-6 text-[#4f3b3a]" />
                      </div>
                    </div>
                    <div className="ml-4">
                      <h2 className="text-lg font-semibold text-[#4f3b3a]">{item.title}</h2>
                      <p className="mt-1 text-[#b2a5a1]">{item.desc}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Process Section */}
            <div className="bg-[#f7f5f5] p-8 rounded-lg">
              <h3 className="text-2xl font-bold text-[#4f3b3a] mb-6">Our Process</h3>
              <div className="space-y-8">
                {[
                  { step: "01", title: "Initial Consultation", desc: "We assess your property portfolio and understand your management needs" },
                  { step: "02", title: "Custom Strategy", desc: "Develop a tailored management plan aligned with your objectives" },
                  { step: "03", title: "Implementation", desc: "Seamless transition and setup of management services" },
                  { step: "04", title: "Ongoing Support", desc: "Regular reviews and continuous optimisation of services" }
                ].map((item, i) => (
                  <div key={i} className="relative pl-14">
                    <span className="absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-full bg-white text-[#4f3b3a] font-bold">
                      {item.step}
                    </span>
                    <div>
                      <h4 className="text-lg font-semibold text-[#4f3b3a]">{item.title}</h4>
                      <p className="mt-1 text-[#b2a5a1]">{item.desc}</p>
                    </div>
                    {i < 3 && (
                      <div className="absolute left-5 top-10 h-full w-px bg-[#b2a5a1]/20" />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Services Section */}
      <div className="bg-gradient-to-br from-white to-[#f7f5f5] py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-[#4f3b3a] sm:text-4xl">
              Comprehensive Solutions
            </h2>
            <p className="mt-4 text-xl text-[#b2a5a1] max-w-2xl mx-auto">
              Expert property management services tailored to your needs
            </p>
          </div>

          <div className="mt-16 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
            {services.map((service, i) => (
              <div
                key={i}
                /* className="relative group cursor-pointer" */
                onMouseEnter={() => setActiveService(i)}
                onMouseLeave={() => setActiveService(null)}
              >
                <div className="absolute -inset-0.5 bg-[#4f3b3a] opacity-0 group-hover:opacity-10 rounded-lg blur transition duration-300"></div>
                <div className="relative bg-[#f7f5f5] p-6 rounded-lg shadow-lg transition-all duration-300 h-full">
                  <div className="flex justify-center">
                    <service.icon className="h-12 w-12 text-[#4f3b3a] transform group-hover:scale-110 transition-transform duration-300" />
                  </div>
                  <h3 className="mt-4 text-lg font-semibold text-[#4f3b3a]">{service.title}</h3>
                  <p className="mt-2 text-[#b2a5a1]">{service.desc}</p>

                  <div className={`mt-4 space-y-2 transition-all duration-300 ${activeService === i ? 'opacity-100 max-h-40' : 'opacity-0 max-h-0'} overflow-hidden`}>
                    {service.features.map((feature, j) => (
                      <div key={j} className="flex items-center text-sm text-[#b2a5a1]">
                        <Star className="h-4 w-4 text-[#4f3b3a] mr-2" />
                        {feature}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>



    {/* Detailed Service Information */}
    <div className="mt-24 grid grid-cols-1 lg:grid-cols-2 gap-12">
      {/* Left Column - Why Choose Us + CTA */}
      <div className="bg-[#f7f5f5] p-8 rounded-lg border border-[#b2a5a1]/20">
    <h3 className="text-2xl font-bold text-[#4f3b3a] mb-6">Why Choose Nova Asset Management</h3>
    <div className="space-y-6">
          {[
            {
              title: "Industry Expertise",
              desc: "Over 20 years of combined experience in property asset management",
              icon: Building2
            },
            {
              title: "Technology-Driven",
              desc: "Advanced property management software and reporting tools",
              icon: Laptop
            },
            {
              title: "Dedicated Support",
              desc: "24/7 emergency response and dedicated property managers",
              icon: Headset
            },
            {
              title: "Transparent Pricing",
              desc: "Clear fee structure with no hidden costs",
              icon: PoundSterling
            }
          ].map((item, i) => (
            <div key={i} className="flex items-start">
            <div className="flex-shrink-0">
              <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-white">
                <item.icon className="h-6 w-6 text-[#4f3b3a]" />
              </div>
            </div>
            <div className="ml-4">
              <h4 className="text-lg font-semibold text-[#4f3b3a]">{item.title}</h4>
              <p className="mt-1 text-[#b2a5a1]">{item.desc}</p>
            </div>
          </div>
        ))}
      </div>

      {/* CTA Section - Moved inside Why Choose Us */}
      <div className="mt-8 pt-8 border-t border-[#b2a5a1]/20">
      <div className="text-center">
        <h3 className="text-2xl font-bold text-[#4f3b3a]">Ready to get started?</h3>
        <p className="mt-2 text-[#b2a5a1] mb-6">
          Let us help you optimise your property portfolio
        </p>
        <Link
          to="/contact"
          className="inline-flex items-center px-8 py-3 bg-[#4f3b3a] text-white rounded-lg font-medium hover:bg-[#4f3b3a]/90 transition-colors duration-200 group"
        >
          Schedule a Consultation
          <ArrowRight className="ml-2 transform group-hover:translate-x-1 transition-transform" />
        </Link>
      </div>
    </div>
  </div>

    {/* Right Column - Contact Form */}
    <div className="bg-[#f7f5f5] p-8 rounded-lg border border-[#b2a5a1]/20">
    <h2 className="text-2xl font-bold text-[#4f3b3a] mb-6">Get Started Today</h2>
      <form onSubmit={(e) => { e.preventDefault(); triggerNotification(); }} className="space-y-4">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <div>
            <label className="block text-sm font-medium text-[#4f3b3a] mb-1">
              First Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              required
              className="w-full px-4 py-2 bg-white border border-[#b2a5a1]/20 rounded-lg text-[#4f3b3a] placeholder-[#b2a5a1] focus:outline-none focus:ring-2 focus:ring-[#4f3b3a]/20"
              placeholder="John"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-[#4f3b3a] mb-1">
              Last Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              required
              className="w-full px-4 py-2 bg-white border border-[#b2a5a1]/20 rounded-lg text-[#4f3b3a] placeholder-[#b2a5a1] focus:outline-none focus:ring-2 focus:ring-[#4f3b3a]/20"
              placeholder="Doe"
            />
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-[#4f3b3a] mb-1">
            Email <span className="text-red-500">*</span>
          </label>
          <input
            type="email"
            required
            className="w-full px-4 py-2 bg-white border border-[#b2a5a1]/20 rounded-lg text-[#4f3b3a] placeholder-[#b2a5a1] focus:outline-none focus:ring-2 focus:ring-[#4f3b3a]/20"
            placeholder="john@example.com"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-[#4f3b3a] mb-1">
            Phone <span className="text-red-500">*</span>
          </label>
          <input
            type="tel"
            required
            className="w-full px-4 py-2 bg-white border border-[#b2a5a1]/20 rounded-lg text-[#4f3b3a] placeholder-[#b2a5a1] focus:outline-none focus:ring-2 focus:ring-[#4f3b3a]/20"
            placeholder="0749 503 1435"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-[#4f3b3a] mb-1">
            Property Type <span className="text-red-500">*</span>
          </label>
          <select 
            required
            className="w-full px-4 py-2 bg-white border border-[#b2a5a1]/20 rounded-lg text-[#4f3b3a] placeholder-[#b2a5a1] focus:outline-none focus:ring-2 focus:ring-[#4f3b3a]/20"
          >
            <option value="">Select property type</option>
            <option value="residential">Residential Property</option>
            <option value="commercial">Commercial Property</option>
            <option value="block">Residential Block</option>
            <option value="mixed">Mixed Use</option>
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-[#4f3b3a] mb-1">
            Message <span className="text-red-500">*</span>
          </label>
          <textarea
            required
            rows="3"
            className="w-full px-4 py-2 bg-white border border-[#b2a5a1]/20 rounded-lg text-[#4f3b3a] placeholder-[#b2a5a1] focus:outline-none focus:ring-2 focus:ring-[#4f3b3a]/20"
            placeholder="Tell us about your property management needs..."
          ></textarea>
        </div>
        <div className="flex items-center">
          <input 
            type="checkbox" 
            className="h-4 w-4 border-white/10 rounded" 
          />
          <label className="ml-2 text-sm text-[#b2a5a1]">
            I agree to receive communications from Nova Asset Management
          </label>
        </div>
        <button
          type="submit"
          className="w-full px-8 py-3 bg-[#4f3b3a] text-white rounded-lg font-medium hover:bg-[#4f3b3a]/90 transition-colors duration-200 flex items-center justify-center group"
        >
          Submit Request
          <ArrowRight className="ml-2 transform group-hover:translate-x-1 transition-transform" />
        </button>
      </form>
      </div>
    </div>
  </div>
</div>
      

      {/* Properties Section */}
      <div className="py-24 bg-gradient-to-br from-white to-[#f7f5f5]">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-[#4f3b3a] sm:text-4xl mb-4">
              Featured Properties
            </h2>
            <p className="text-xl text-[#b2a5a1] max-w-2xl mx-auto">
              Discover our portfolio of expertly managed properties across the UK
            </p>
          </div>

          {/* Property Type Filters 
          <div className="flex justify-center gap-4 mb-12">
            {["All", "Residential", "Commercial", "Mixed Use"].map((type) => (
              <button
                key={type}
                className="px-6 py-2 rounded-full text-sm font-medium transition-all duration-200
                  border border-[#b2a5a1] text-[#4f3b3a] hover:bg-[#f7f5f5]"
              >
                {type}
              </button>
            ))}
          </div> */}

          {/* Property Cards */}
          <div className="mt-12 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {properties.map((property, i) => (
              <div
                key={i}
                className="group cursor-pointer bg-[#f7f5f5] rounded-xl shadow-md overflow-hidden hover:shadow-xl transition-all duration-300"
              >

          {/* Image Container */}
          <div className="relative">
            <div className="relative pb-[65%] overflow-hidden">
              <img 
                src={property.img}
                alt={property.title}
                className="absolute inset-0 w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-500"
              />
            </div>
            {/* Property Type Badge */}
            <div className="absolute top-4 left-4">
              <span className="px-3 py-1 bg-white/90 backdrop-blur-sm text-[#8c7f7c] text-sm font-medium rounded-full">
                {property.type}
              </span>
            </div>
            {/* Status Badge */}
            <div className="absolute top-4 right-4">
              <span className="px-3 py-1 bg-[#b2a5a1]/90 backdrop-blur-sm text-white text-sm font-medium rounded-full">
                {property.status}
              </span>
            </div>
          </div>

          {/* Content */}
          <div className="p-6">
  <div className="flex items-start justify-between mb-4">
    <div>
      <h3 className="text-xl font-semibold text-[#4f3b3a] group-hover:text-[#4f3b3a]/80 transition-colors duration-200">
        {property.title}
      </h3>
      <p className="text-[#b2a5a1] flex items-center mt-1">
        <MapPin className="h-4 w-4 mr-1" />
        {property.location}
      </p>
    </div>
  </div>

  <p className="text-[#b2a5a1] mb-6">{property.desc}</p>

            {/* Stats Grid */}
            <div className="grid grid-cols-3 gap-4 mb-6">
    {Object.entries(property.stats).map(([key, value]) => (
      <div key={key} className="text-center p-2 bg-white rounded-lg">
        <p className="text-sm font-medium text-[#4f3b3a]">{value}</p>
        <p className="text-xs text-[#b2a5a1] capitalize">{key}</p>
      </div>
    ))}
  </div>

            {/* Features */}
            <div className="mb-6">
    <div className="flex flex-wrap gap-2">
      {property.features.map((feature, index) => (
        <span 
          key={index}
          className="px-3 py-1 bg-white text-[#b2a5a1] text-sm rounded-full border border-[#b2a5a1]/20"
        >
          {feature}
        </span>
      ))}
    </div>
  </div>

            {/* Action Buttons */}
            <div className="flex gap-4">
              <button 
                onClick={() => window.open(`/properties?property=${property.title}`)}
                /* onClick={() => navigate(`/properties?property=${property.title}`)}*/
                className="flex-1 px-4 py-2 bg-[#4f3b3a] text-white text-sm font-medium rounded-lg hover:bg-[#4f3b3a]/90 transition-colors duration-200 flex items-center justify-center group"
              >
                View Details
                <ArrowRight className="ml-2 h-4 w-4 transform group-hover:translate-x-1 transition-transform" />
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>

    {/* View All Properties CTA */}
    <div className="mt-16 text-center">
        <button 
        onClick={() => navigate('/properties')}
        className="inline-flex items-center px-8 py-3 border-2 border-[#b2a5a1]/20 text-[#4f3b3a] font-medium rounded-lg hover:bg-[#f7f5f5] transition-all duration-200 group"
      >
        View All Properties
        <ArrowRight className="ml-2 h-5 w-5 transform group-hover:translate-x-1 transition-transform" />
      </button>
    </div>
  </div>
</div>

{/* Testimonials */}
<div className="bg-gradient-to-br from-white to-[#f7f5f5] py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-[#4f3b3a] sm:text-4xl mb-4">
              Client Success Stories
            </h2>
            <p className="text-xl text-[#b2a5a1] max-w-2xl mx-auto">
              Discover how we've helped property owners and managers achieve their goals
            </p>
          </div>

          {/* Featured Testimonial */}
          {testimonials.map((testimonial, index) => (
            <div 
            key={index}
            className={`relative bg-[#f7f5f5] rounded-2xl p-8 lg:p-12 shadow-xl border border-[#b2a5a1]/20 transition-opacity duration-500 ${
              activeIndex === index ? 'opacity-100' : 'hidden'
            }`}
          >
            <Quote className="absolute top-8 left-8 h-12 w-12 text-[#b2a5a1]/20" />
              
              <div className="grid lg:grid-cols-3 gap-8 items-center">
                {/* Left Column - Author Info */}
                <div className="text-center lg:text-left">
      <div className="flex items-center justify-center lg:justify-start mb-4">
        <div className="relative">
          <div className="w-24 h-24 rounded-full overflow-hidden border-4 border-[#b2a5a1]/20">
            <img 
              src={testimonial.image}
              alt={testimonial.author}
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
      <h3 className="text-xl font-semibold text-[#4f3b3a]">
        {testimonial.author}
      </h3>
      <p className="text-[#b2a5a1] mt-1">
        {testimonial.position}
      </p>
      <p className="text-[#b2a5a1] text-sm">
        {testimonial.company}
      </p>
                  <div className="flex items-center justify-center lg:justify-start mt-3">
                    {[...Array(testimonial.rating)].map((_, i) => (
                      <Star key={i} className="h-5 w-5 text-yellow-400 fill-current" />
                    ))}
                  </div>
                </div>

                {/* Middle Column - Main Quote */}
                <div className="lg:col-span-2">
                  <p className="text-lg text-[#b2a5a1] leading-relaxed">
                    {testimonial.text}
                  </p>
                  
                  <div className="mt-8 grid grid-cols-2 md:grid-cols-3 gap-4">
                    <div className="bg-white/5 rounded-lg p-4">
                      <Building2 className="h-5 w-5 text-[#4f3b3a] mb-2" />
                      <p className="text-sm text-[#b2a5a1]">{testimonial.propertyType}</p>
                    </div>
                    <div className="bg-white/5 rounded-lg p-4">
                      <User2 className="h-5 w-5 text-[#4f3b3a] mb-2" />
                      <p className="text-sm text-[#b2a5a1]">{testimonial.duration}</p>
                    </div>
                    <div className="bg-white/5 rounded-lg p-4">
                      <Building2 className="h-5 w-5 text-[#4f3b3a] mb-2" />
                      <p className="text-sm text-[#b2a5a1]">{testimonial.portfolio}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

          {/* Navigation */}
          <div className="mt-8 flex justify-center items-center space-x-4">
  <button 
    onClick={() => setActiveIndex((prev) => (prev - 1 + testimonials.length) % testimonials.length)}
    className="p-2 rounded-full bg-[#f7f5f5] hover:bg-[#b2a5a1]/10 transition-colors duration-200"
  >
    <ChevronLeft className="h-6 w-6 text-[#4f3b3a]" />
  </button>
            
            {/* Dots */}
            <div className="flex space-x-2">
    {testimonials.map((_, idx) => (
      <button
        key={idx}
        onClick={() => setActiveIndex(idx)}
        className={`w-2 h-2 rounded-full transition-all duration-200 ${
          activeIndex === idx ? 'bg-[#4f3b3a] w-4' : 'bg-[#b2a5a1]/50'
        }`}
      />
    ))}
  </div>

  <button 
    onClick={() => setActiveIndex((prev) => (prev + 1) % testimonials.length)}
    className="p-2 rounded-full bg-[#f7f5f5] hover:bg-[#b2a5a1]/10 transition-colors duration-200"
  >
    <ChevronRight className="h-6 w-6 text-[#4f3b3a]" />
  </button>
</div>

          {/* Stats
          <div className="mt-16 grid grid-cols-2 md:grid-cols-4 gap-8">
            {[
              { label: "Properties Managed", value: "500+" },
              { label: "Client Satisfaction", value: "98%" },
              { label: "Years Experience", value: "15+" },
              { label: "Client Retention", value: "95%" }
            ].map((stat, i) => (
              <div key={i} className="text-center">
              <p className="text-3xl font-bold text-[#4f3b3a] mb-2">{stat.value}</p>
                <p className="text-[#b2a5a1]">{stat.label}</p>
              </div>
            ))}
          </div>    */}
  </div>
</div>

{/* Contact */}
<div className="bg-[#2c2827]">
  <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
    <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
      {[
        {
          icon: Phone,
          text: "0749 503 1435",
          action: () => window.location.href = "tel:+442012345678"
        },
        {
          icon: Mail,
          text: "info@novalimited.co.uk",
          action: () => window.location.href = "mailto:info@novalimited.co.uk"
        },
        {
          icon: MapPin,
          text: "83 Wimbledon Park Side, SW19 5LP",
          action: () => window.open("https://maps.app.goo.gl/6fTsgFR4MS9x7PAt8", "_blank")
        }
      ].map((item, i) => (
        <button
          key={i}
          onClick={item.action}
          className="flex items-center p-4 bg-[#3d3533] rounded-lg shadow-sm border border-[#4d4341] hover:border-[#b2a5a1] hover:shadow-md transition-all duration-200 group w-full"
        >
          <item.icon className="h-6 w-6 text-[#b2a5a1] group-hover:scale-110 transition-transform" />
          <span className="ml-3 text-[#968a87] group-hover:text-[#b2a5a1] transition-colors duration-200">
            {item.text}
          </span>
        </button>
      ))}
    </div>
  </div>
</div>

{/* Footer */}
<footer className="bg-[#2c2827] border-t border-[#3d3533]">
  <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
    <div className="grid grid-cols-2 gap-8 md:grid-cols-4">
      {[
        { 
          title: "Company", 
          links: [
            { name: "About", path: "/about" },
            { name: "Services", path: "/services" },
            { name: "Properties", path: "/properties" },
            { name: "Contact", path: "/contact" }
          ]
        },
        { 
          title: "Services", 
          links: [
            { name: "Property Management", sectionId: "property-management" },
            { name: "Planning, Design & Build", sectionId: "development" }
          ]
        },
        { 
          title: "Legal", 
          links: [
            { name: "Privacy", path: "/privacy", type: "pdf" },
            { name: "Data Protection", path: "/Registration Certificate.pdf", type: "pdf" },
            { name: "Terms", path: "/terms" }
          ]
        },
        { 
          title: "Social", 
          links: [
            { name: "LinkedIn", path: "https://linkedin.com" },
            { name: "Twitter", path: "https://twitter.com" },
            { name: "Facebook", path: "https://facebook.com" }
          ]
        }
      ].map((section, i) => (
        <div key={i}>
          <h3 className="text-sm font-semibold text-[#b2a5a1] tracking-wider uppercase">
            {section.title}
          </h3>
          <ul className="mt-4 space-y-4">
            {section.links.map((link, j) => (
              <li key={j}>
                {section.title === "Social" ? (
                  <a 
                    href={link.path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-base text-[#968a87] hover:text-[#b2a5a1] transition-colors duration-200 flex items-center group"
                  >
                    <span>{link.name}</span>
                    <ArrowRight className="ml-2 opacity-0 group-hover:opacity-100 transform -translate-x-2 group-hover:translate-x-0 transition-all duration-200 text-[#b2a5a1]" size={14} />
                  </a>
                ) : section.title === "Services" ? (
                  <button
                    onClick={() => navigate(`/services#${link.sectionId}`)}
                    className="text-base text-[#968a87] hover:text-[#b2a5a1] transition-colors duration-200 flex items-center group"
                  >
                    <span>{link.name}</span>
                    <ArrowRight className="ml-2 opacity-0 group-hover:opacity-100 transform -translate-x-2 group-hover:translate-x-0 transition-all duration-200 text-[#b2a5a1]" size={14} />
                  </button>
                ) : link.type === "pdf" ? (
                  <a
                    href={link.path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-base text-[#968a87] hover:text-[#b2a5a1] transition-colors duration-200 flex items-center group"
                  >
                    <span>{link.name}</span>
                    <ArrowRight className="ml-2 opacity-0 group-hover:opacity-100 transform -translate-x-2 group-hover:translate-x-0 transition-all duration-200 text-[#b2a5a1]" size={14} />
                  </a>
                ) : (
                  <Link
                    to={link.path}
                    className="text-base text-[#968a87] hover:text-[#b2a5a1] transition-colors duration-200 flex items-center group"
                  >
                    <span>{link.name}</span>
                    <ArrowRight className="ml-2 opacity-0 group-hover:opacity-100 transform -translate-x-2 group-hover:translate-x-0 transition-all duration-200 text-[#b2a5a1]" size={14} />
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
    <div className="mt-8 border-t border-[#3d3533] pt-8">
      <p className="text-base text-[#968a87]">
        &copy; 2024 Nova Ltd. All rights reserved.
      </p>
    </div>
  </div>
</footer>
    </div>
  );
};

export default PropertyWebsite;