import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  Home,
  Key,
  Clock,
  ArrowRight,
  X,
  Phone,
  Mail,
  MapPin,
  ChevronDown,
  Check,
  Copy,
  ChevronUp,
  MessageCircleMore
} from 'lucide-react';

const ContactPage = () => {
  const navigate = useNavigate();
  const [showNotification, setShowNotification] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hoveredLink, setHoveredLink] = useState(null);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  const [openFaq, setOpenFaq] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [copiedEmail, setCopiedEmail] = useState(false);
  const [copiedPhone, setCopiedPhone] = useState(false);
  
  
  const contactInfo = {
    email: "info@novalimited.co.uk",
    phone: "0749 503 1435"
  };

  const copyToClipboard = async (text, type) => {
    try {
      await navigator.clipboard.writeText(text);
      if (type === 'email') {
        setCopiedEmail(true);
        setTimeout(() => setCopiedEmail(false), 2000);
      } else {
        setCopiedPhone(true);
        setTimeout(() => setCopiedPhone(false), 2000);
      }
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };


  // Handle scroll events
  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 150);
      
      // Update active section based on scroll position
      const sections = document.querySelectorAll('section[id]');
      const scrollY = window.scrollY;

      sections.forEach(section => {
        const sectionTop = section.offsetTop - 100;
        const sectionHeight = section.offsetHeight;
        const sectionId = section.getAttribute('id');

        if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
          setActiveSection(sectionId);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  const triggerNotification = () => {
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 3000);
  };

  const [isHidden, setIsHidden] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  
  useEffect(() => {
    const controlHeader = () => {
      const currentScrollY = window.scrollY;
  
      if (currentScrollY > lastScrollY && currentScrollY > 100) {
        setIsHidden(true);
      } else {
        setIsHidden(false);
      }
      
      setLastScrollY(currentScrollY);
    };
  
    window.addEventListener('scroll', controlHeader);
    return () => window.removeEventListener('scroll', controlHeader);
  }, [lastScrollY]);
  
  const location = useLocation();




  const faqs = [
    {
      question: "How quickly can you start managing my property?",
      answer: "We can typically begin managing your property within 2-3 weeks of signing our management agreement. This allows time for proper documentation and setup of your account."
    },
    {
      question: "What areas do you cover?",
      answer: "We currently manage properties throughout Greater London and surrounding areas. Contact us to check if we cover your specific location."
    },
    {
      question: "?",
      answer: "."
    },
    {
      question: "Do you handle maintenance and repairs?",
      answer: "Yes, we have a network of trusted contractors and handle all aspects of property maintenance, from routine repairs to emergency situations."
    },
    {
      question: "How do you screen potential tenants?",
      answer: "We conduct thorough background checks including credit history, employment verification, previous landlord references, and identity verification."
    },
    {
      question: "What reports do landlords receive?",
      answer: "Landlords receive monthly financial statements, maintenance reports, and regular property inspection updates through our online portal."
    }
  ];

  return (
    <div className="min-h-screen bg-white">
      {/* Notification */}
      {showNotification && (
        <div className="fixed top-4 right-4 z-50 animate-slide-in-right">
          <div className="bg-[#b2a5a1] text-white px-6 py-3 rounded-lg shadow-lg flex items-center">
            <span>Thanks for your message! We'll be in touch soon.</span>
            <button onClick={() => setShowNotification(false)} className="ml-3 hover:text-neutral-200">
              <X size={18} />
            </button>
          </div>
        </div>
      )}

      {/* Scroll to top button */}
      <button
        onClick={scrollToTop}
        className={`fixed bottom-8 right-8 z-40 p-3 bg-[#b2a5a1] text-white rounded-full shadow-lg transition-all duration-300 hover:bg-[#8c7f7c] ${
          showScrollTop ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-16'
        }`}
      >
        <ChevronUp size={24} />
      </button>

{/* Header */}
<header 
  className={`
    bg-white border-b border-[#eae7e6] sticky top-0 z-40 
    transition-transform duration-300 
    ${isHidden ? '-translate-y-full' : 'translate-y-0'}
  `}
>    
  <div className="max-w-7xl mx-auto px-4 sm:px-6">
    <div className="flex justify-between items-center h-20">
      {/* Logo */}
      <div className="w-64"> 
        <Link to="/" className="flex items-center group cursor-pointer">
          <img 
            src="/header-logo-new.png" 
            alt="Nova Asset Management" 
            className="h-12 md:h-14 w-auto object-contain md:object-fill transform group-hover:scale-105 transition-transform duration-200"
          />
        </Link>
      </div>


      {/* Centered Desktop Navigation */}
      <nav className="hidden md:flex flex-1 justify-center">
        <div className="flex space-x-12">
          {["Home", "About", "Services", "Properties", "Contact"].map((item) => (
            <div
              key={item}
              className="relative group"
              onMouseEnter={() => {
                setHoveredLink(item);
                if (item === "Services") setOpenDropdown(true);
              }}
              onMouseLeave={() => {
                setHoveredLink(null);
                if (item === "Services") setOpenDropdown(false);
              }}
            >
              <Link
                to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
                className={`
                  relative text-[#8c7f7c] hover:text-[#b2a5a1] font-medium 
                  transition-colors duration-200 py-2 flex items-center 
                  ${(item === "Home" && location.pathname === "/") || 
                    (item !== "Home" && location.pathname === `/${item.toLowerCase()}`)
                      ? "text-[#b2a5a1]" 
                      : ""
                  }
                `}
              >
                {item}
                {item === "Services" && (
                  <ChevronDown className="ml-1 w-4 h-4" />
                )}
                <span 
                  className={`
                    absolute bottom-0 left-0 w-full h-0.5 bg-[#b2a5a1] 
                    transform origin-left transition-transform duration-200 
                    ${((item === "Home" && location.pathname === "/") || 
                       (item !== "Home" && location.pathname === `/${item.toLowerCase()}`) ||
                       hoveredLink === item) ? 'scale-x-100' : 'scale-x-0'
                    }
                  `}
                />
              </Link>

              {/* Services Dropdown */}
              {item === "Services" && (
                <div className={`
                  absolute left-1/2 transform -translate-x-1/2 mt-2 w-72 
                  bg-white rounded-lg shadow-lg border border-[#eae7e6] 
                  transition-all duration-200 
                  ${openDropdown ? 'opacity-100 visible translate-y-0' : 'opacity-0 invisible -translate-y-2'}
                `}>
                  <div className="p-4 space-y-3">
                    {[
                      { 
                        icon: Home, 
                        title: "Property Management",
                        sectionId: "property-management", 
                        subItems: [
                          { name: "Residential Lettings", id: "residential-lettings" },
                          { name: "Short Term Luxury Lettings", id: "short-term-luxury-lettings" },
                          { name: "Portfolio Management", id: "portfolio-management" }
                        ] 
                      },
                      { 
                        icon: Key, 
                        title: "Planning, Design & Build",
                        sectionId: "development", 
                        subItems: [
                          { name: "Planning", id: "planning" },
                          { name: "Design and Build", id: "design-and-build" }
                        ] 
                      }
                    ].map((service, index) => (
                      <div
                        key={index}
                        className="relative group/item"
                        onMouseEnter={() => setHoveredLink(service.title)}
                        onMouseLeave={() => setHoveredLink(null)}
                      >
                        <div 
                          className="flex items-center justify-between px-3 py-2 rounded-md hover:bg-[#f7f5f5] text-[#8c7f7c] hover:text-[#b2a5a1] transition-colors duration-200 cursor-pointer"
                          onClick={() => {
                            navigate(`/services#${service.sectionId}`);
                            setOpenDropdown(false);
                            setHoveredLink(null);
                          }}
                        >
                          <div className="flex items-center space-x-3 min-w-0">
                            <service.icon className="w-5 h-5 flex-shrink-0" />
                            <span className="font-medium whitespace-nowrap">{service.title}</span>
                          </div>
                          <ChevronDown className="w-4 h-4 flex-shrink-0 ml-3" />
                        </div>

                        {/* Nested Dropdown */}
                        <div className={`
                          absolute left-full top-0 w-56 bg-white rounded-lg 
                          shadow-lg border border-[#eae7e6] ml-2 transition-all duration-200
                          ${hoveredLink === service.title ? 'opacity-100 visible translate-x-0' : 'opacity-0 invisible -translate-x-2'}
                        `}>
                          <div className="py-2">
                            {service.subItems.map((subItem, subIndex) => (
                              <button
                                key={subIndex}
                                onClick={() => {
                                  navigate(`/services#${subItem.id}`);
                                  setOpenDropdown(false);
                                  setHoveredLink(null);
                                }}
                                className="w-full text-left px-4 py-2 text-[#8c7f7c] hover:text-[#b2a5a1] hover:bg-[#f7f5f5] text-sm transition-colors duration-200"
                              >
                                {subItem.name}
                              </button>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </nav>

      {/* Right Section - Contact Icons and Mobile Menu */}
      <div className="w-64 flex items-center justify-end">
        {/* Contact Icons - Desktop Only */}
        <div className="hidden md:flex items-center gap-4 mr-4">
          {/* Email Icon with Tooltip */}
          <div className="relative">
            <div className="p-2 group">
              <Mail 
                size={20} 
                className="text-[#8c7f7c] group-hover:text-[#b2a5a1] cursor-pointer"
              />
              {/* Email Tooltip */}
              <div className="absolute right-0 mt-2 w-auto min-w-max invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-200 bg-white border border-[#eae7e6] rounded-lg shadow-lg p-3 z-50">
                <div className="flex items-center space-x-2 whitespace-nowrap">
                  <span className="text-[#8c7f7c]">{contactInfo.email}</span>
                  <button 
                    onClick={(e) => {
                      e.stopPropagation();
                      copyToClipboard(contactInfo.email, 'email');
                    }}
                    className="text-[#8c7f7c] hover:text-[#b2a5a1] transition-colors duration-200 ml-2"
                  >
                    {copiedEmail ? <Check size={16} /> : <Copy size={16} />}
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Phone Icon with Tooltip */}
          <div className="relative">
            <div className="p-2 group">
              <Phone 
                size={20} 
                className="text-[#8c7f7c] group-hover:text-[#b2a5a1] cursor-pointer"
              />
              {/* Phone Tooltip */}
              <div className="absolute right-0 mt-2 w-auto min-w-max invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-200 bg-white border border-[#eae7e6] rounded-lg shadow-lg p-3 z-50">
                <div className="flex items-center space-x-2 whitespace-nowrap">
                  <span className="text-[#8c7f7c]">{contactInfo.phone}</span>
                  <button 
                    onClick={(e) => {
                      e.stopPropagation();
                      copyToClipboard(contactInfo.phone, 'phone');
                    }}
                    className="text-[#8c7f7c] hover:text-[#b2a5a1] transition-colors duration-200 ml-2"
                  >
                    {copiedPhone ? <Check size={16} /> : <Copy size={16} />}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile menu button */}
        <button 
          className="md:hidden relative group p-2"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <div className="flex flex-col space-y-1.5">
            <span className={`block w-6 h-0.5 bg-[#8c7f7c] transform transition-all duration-200 ${isMenuOpen ? 'rotate-45 translate-y-2' : ''}`}></span>
            <span className={`block w-6 h-0.5 bg-[#8c7f7c] transition-all duration-200 ${isMenuOpen ? 'opacity-0' : ''}`}></span>
            <span className={`block w-6 h-0.5 bg-[#8c7f7c] transform transition-all duration-200 ${isMenuOpen ? '-rotate-45 -translate-y-2' : ''}`}></span>
          </div>
        </button>
      </div>
    </div>
  </div>

{/* Mobile Navigation */}
<div className={`md:hidden bg-white overflow-hidden transition-all duration-300 ${isMenuOpen ? 'max-h-screen' : 'max-h-0'}`}>
  <div className="px-4 py-3 space-y-2">
    {["Home", "About", "Services", "Properties", "Contact"].map((item) => (
      <div key={item}>
        {item === "Services" ? (
          <div>
            <button
              className={`w-full px-4 py-3 text-center hover:text-[#b2a5a1] hover:bg-[#f7f5f5] rounded-lg transition-all duration-200 ${
                location.pathname === "/services" ? "text-[#b2a5a1] bg-[#f7f5f5]" : "text-[#8c7f7c]"
              }`}
              onClick={() => setOpenDropdown(!openDropdown)}
            >
              <div className="flex items-center justify-center">
                Services
                <ChevronDown 
                  className={`ml-1 w-4 h-4 transform transition-transform duration-200 ${
                    openDropdown ? 'rotate-180' : ''
                  }`} 
                />
              </div>
            </button>
            
            {/* Mobile Services Dropdown */}
            <div className={`overflow-hidden transition-all duration-200 ${openDropdown ? 'max-h-96' : 'max-h-0'}`}>
              <div className="py-2 px-4 space-y-2">
                {[
                  { 
                    icon: Home, 
                    title: "Property Management",
                    sectionId: "property-management",
                    subItems: [
                      { name: "Residential Lettings", id: "residential-lettings" },
                      { name: "Short Term Luxury Lettings", id: "short-term-luxury-lettings" },
                      { name: "Portfolio Management", id: "portfolio-management" }
                    ]
                  },
                  { 
                    icon: Key, 
                    title: "Planning, Design & Build",
                    sectionId: "development",
                    subItems: [
                      { name: "Planning", id: "planning" },
                      { name: "Design and Build", id: "design-and-build" }
                    ]
                  }
                ].map((service, index) => (
                  <div key={index} className="space-y-2">
                    <button
                      onClick={() => {
                        navigate(`/services#${service.sectionId}`);
                        setIsMenuOpen(false);
                        setOpenDropdown(false);
                      }}
                      className="w-full flex items-center justify-between px-4 py-2 rounded-md hover:bg-[#f7f5f5] text-[#8c7f7c] hover:text-[#b2a5a1] transition-colors duration-200"
                    >
                      <div className="flex items-center space-x-3">
                        <service.icon className="w-5 h-5" />
                        <span className="font-medium">{service.title}</span>
                      </div>
                    </button>
                      
                    {/* Sub-items */}
                    <div className="pl-11 space-y-1">
                      {service.subItems.map((subItem, subIndex) => (
                        <button
                          key={subIndex}
                          onClick={() => {
                            navigate(`/services#${subItem.id}`);
                            setIsMenuOpen(false);
                            setOpenDropdown(false);
                          }}
                          className="w-full text-left py-2 px-4 text-[#8c7f7c] hover:text-[#b2a5a1] hover:bg-[#f7f5f5] rounded-md text-sm transition-colors duration-200"
                        >
                          {subItem.name}
                        </button>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          ) : (
            <Link
              to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
              className={`block px-4 py-3 text-center hover:text-[#b2a5a1] hover:bg-[#f7f5f5] rounded-lg transition-all duration-200 ${
                (item === "Home" && location.pathname === "/") || 
                (item !== "Home" && location.pathname === `/${item.toLowerCase()}`)
                  ? "text-[#b2a5a1] bg-[#f7f5f5]"
                  : "text-[#8c7f7c]"
              }`}
              onClick={() => setIsMenuOpen(false)}
            >
              <div className="flex items-center justify-center">
                {item}
              </div>
            </Link>
          )}
        </div>
      ))}
    </div>
  </div>
</header>

      {/* Main Content */}
      <div className="bg-gradient-to-br from-white to-[#f7f5f5]">
        {/* Hero Section */}
        <section className="py-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <div className="text-center">
              <h1 className="text-4xl font-bold text-[#4f3b3a] sm:text-5xl mb-6">Get in Touch</h1>
              <p className="text-xl text-[#b2a5a1] max-w-2xl mx-auto">
                Our property management experts are here to help with your inquiries
              </p>
            </div>

{/* Contact Methods */}
<div className="mt-16 grid grid-cols-1 md:grid-cols-5 gap-8">
  {[
    { 
      icon: Phone, 
      text: "0749 503 1435", 
      label: "Call us",
      action: () => window.location.href = "tel:+442012345678"
    },
    { 
      icon: Mail, 
      text: "info@novalimited.co.uk", 
      label: "Email us",
      action: () => window.location.href = "mailto:info@novalimited.co.uk"
    },
    { 
      icon: Clock, 
      text: "Mon-Fri: 8am-7pm", 
      label: "Office Hours"
    },
    { 
      icon: MapPin, 
      text: "83 Wimbledon Park Side, SW19 5LP", 
      label: "Visit us",
      action: () => window.open("https://maps.app.goo.gl/6fTsgFR4MS9x7PAt8", "_blank")
    },
    { 
      icon: MessageCircleMore, 
      text: "0749 503 1435", 
      label: "Whatsapp",
      action: () => window.open("https://wa.me/442012345678", "_blank")
    },
  ].map((item, i) => (
    <button 
      key={i} 
      onClick={item.action}
      disabled={!item.action}
      className={`bg-[#f7f5f5] p-6 rounded-lg border border-[#b2a5a1]/20 text-center transform hover:scale-105 transition-all duration-200 w-full
        ${item.action ? 'cursor-pointer' : 'cursor-default'}
        ${item.action ? 'hover:border-[#b2a5a1]/40' : ''}`}
    >
      <div className="flex justify-center mb-4">
        <div className="p-3 bg-[#f0edec] rounded-lg">
          <item.icon className="h-6 w-6 text-[#4f3b3a]" />
        </div>
      </div>
      <h3 className="text-lg font-semibold text-[#4f3b3a] mb-2">{item.label}</h3>
      <p className="text-[#b2a5a1]">{item.text}</p>
    </button>
  ))}
</div>

            {/* Contact Form and FAQ Section */}
            <div className="mt-16 grid grid-cols-1 lg:grid-cols-2 gap-12">
              {/* Form */}
              <div className="bg-[#f7f5f5] p-8 rounded-lg border border-[#b2a5a1]/20">
                <h2 className="text-2xl font-bold text-[#4f3b3a] mb-6">Send us a Message</h2>
                <form onSubmit={(e) => { e.preventDefault(); triggerNotification(); }} className="space-y-6">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <div>
                      <label className="block text-sm font-medium text-[#4f3b3a] mb-1">First Name <span className="text-red-500">*</span> </label>
                      <input
                        required
                        type="text"
                        className="w-full px-4 py-2 bg-white border border-[#b2a5a1]/20 rounded-lg text-[#4f3b3a] placeholder-[#b2a5a1] focus:outline-none focus:ring-2 focus:ring-[#4f3b3a]/20"
                        placeholder="John"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-[#4f3b3a] mb-1">Last Name <span className="text-red-500">*</span> </label>
                      <input
                        required
                        type="text"
                        className="w-full px-4 py-2 bg-white border border-[#b2a5a1]/20 rounded-lg text-[#4f3b3a] placeholder-[#b2a5a1] focus:outline-none focus:ring-2 focus:ring-[#4f3b3a]/20"
                        placeholder="Doe"
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-[#4f3b3a] mb-1">Email <span className="text-red-500">*</span> </label>
                    <input
                      required
                      type="email"
                      className="w-full px-4 py-2 bg-white border border-[#b2a5a1]/20 rounded-lg text-[#4f3b3a] placeholder-[#b2a5a1] focus:outline-none focus:ring-2 focus:ring-[#4f3b3a]/20"
                      placeholder="john@example.com"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-[#4f3b3a] mb-1">Message <span className="text-red-500">*</span> </label>
                    <textarea
                      required
                      rows="4"
                      className="w-full px-4 py-2 bg-white border border-[#b2a5a1]/20 rounded-lg text-[#4f3b3a] placeholder-[#b2a5a1] focus:outline-none focus:ring-2 focus:ring-[#4f3b3a]/20"
                      placeholder="How can we help you?"
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    className="w-full px-6 py-3 bg-[#4f3b3a] text-white rounded-lg font-medium hover:bg-[#4f3b3a]/90 transition-colors duration-200 flex items-center justify-center group"
                  >
                    Send Message
                    <ArrowRight className="ml-2 transform group-hover:translate-x-1 transition-transform" />
                  </button>
                </form>
              </div>

              {/* FAQ Section */}
              <div className="bg-[#f7f5f5] p-8 rounded-lg border border-[#b2a5a1]/20">
                <h2 className="text-2xl font-bold text-[#4f3b3a] mb-6">Frequently Asked Questions</h2>
                <div className="space-y-4">
                  {faqs.map((faq, index) => (
                    <div key={index} className="border-b border-[#b2a5a1]/20 last:border-0">
                      <button
                        className="w-full py-4 flex items-center justify-between text-left"
                        onClick={() => setOpenFaq(openFaq === index ? null : index)}
                      >
                        <span className="text-[#4f3b3a] font-medium">{faq.question}</span>
                        <ChevronDown 
                          className={`h-5 w-5 text-[#4f3b3a] transform transition-transform duration-200 ${
                            openFaq === index ? 'rotate-180' : ''
                          }`}
                        />
                      </button>
                      <div className={`overflow-hidden transition-all duration-200 ${
                        openFaq === index ? 'max-h-40 pb-4' : 'max-h-0'
                      }`}>
                        <p className="text-[#b2a5a1]">{faq.answer}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

{/* Footer */}
<footer className="bg-[#2c2827] border-t border-[#3d3533]">
  <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
    <div className="grid grid-cols-2 gap-8 md:grid-cols-4">
      {[
        { 
          title: "Company", 
          links: [
            { name: "Home", path: "/" },
            { name: "About", path: "/about" },
            { name: "Services", path: "/services" },
            { name: "Properties", path: "/properties" }
          ]
        },
        { 
          title: "Services", 
          links: [
            { name: "Property Management", sectionId: "property-management" },
            { name: "Planning, Design & Build", sectionId: "development" }
          ]
        },
        { 
          title: "Legal", 
          links: [
            { name: "Privacy", path: "/privacy", type: "pdf" },
            { name: "Data Protection", path: "/Registration Certificate.pdf", type: "pdf" },
            { name: "Terms", path: "/terms" }
          ]
        },
        { 
          title: "Social", 
          links: [
            { name: "LinkedIn", path: "https://linkedin.com" },
            { name: "Twitter", path: "https://twitter.com" },
            { name: "Facebook", path: "https://facebook.com" }
          ]
        }
      ].map((section, i) => (
        <div key={i}>
          <h3 className="text-sm font-semibold text-[#b2a5a1] tracking-wider uppercase">
            {section.title}
          </h3>
          <ul className="mt-4 space-y-4">
            {section.links.map((link, j) => (
              <li key={j}>
                {section.title === "Social" ? (
                  <a 
                    href={link.path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-base text-[#968a87] hover:text-[#b2a5a1] transition-colors duration-200 flex items-center group"
                  >
                    <span>{link.name}</span>
                    <ArrowRight className="ml-2 opacity-0 group-hover:opacity-100 transform -translate-x-2 group-hover:translate-x-0 transition-all duration-200 text-[#b2a5a1]" size={14} />
                  </a>
                ) : section.title === "Services" ? (
                  <button
                    onClick={() => navigate(`/services#${link.sectionId}`)}
                    className="text-base text-[#968a87] hover:text-[#b2a5a1] transition-colors duration-200 flex items-center group"
                  >
                    <span>{link.name}</span>
                    <ArrowRight className="ml-2 opacity-0 group-hover:opacity-100 transform -translate-x-2 group-hover:translate-x-0 transition-all duration-200 text-[#b2a5a1]" size={14} />
                  </button>
                ) : link.type === "pdf" ? (
                  <a
                    href={link.path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-base text-[#968a87] hover:text-[#b2a5a1] transition-colors duration-200 flex items-center group"
                  >
                    <span>{link.name}</span>
                    <ArrowRight className="ml-2 opacity-0 group-hover:opacity-100 transform -translate-x-2 group-hover:translate-x-0 transition-all duration-200 text-[#b2a5a1]" size={14} />
                  </a>
                ) : (
                  <Link
                    to={link.path}
                    className="text-base text-[#968a87] hover:text-[#b2a5a1] transition-colors duration-200 flex items-center group"
                  >
                    <span>{link.name}</span>
                    <ArrowRight className="ml-2 opacity-0 group-hover:opacity-100 transform -translate-x-2 group-hover:translate-x-0 transition-all duration-200 text-[#b2a5a1]" size={14} />
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
    <div className="mt-8 border-t border-[#3d3533] pt-8">
      <p className="text-base text-[#968a87]">
        &copy; 2024 Nova Ltd. All rights reserved.
      </p>
    </div>
  </div>
</footer>
    </div>
  );
};

export default ContactPage;