import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  Building2, 
  Home,
  Key,
  Users,
  ClipboardCheck,
  ArrowRight,
  Star,
  Wrench,
  Shield,
  PoundSterling,
  X,
  Phone,
  Mail,
  MapPin,
  ChevronDown,
  Building,
  FileSpreadsheet,
  Combine,
  HardHat,
  Check,
  Copy,
  ChevronUp
} from 'lucide-react';

const ServicesPage = () => {
  const [showNotification, setShowNotification] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hoveredLink, setHoveredLink] = useState(null);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  const [activeService, setActiveService] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [copiedEmail, setCopiedEmail] = useState(false);
  const [copiedPhone, setCopiedPhone] = useState(false);
  const [selectedService, setSelectedService] = useState(null);


  const contactInfo = {
    email: "info@novalimited.co.uk",
    phone: "0749 503 1435"
  };

  const copyToClipboard = async (text, type) => {
    try {
      await navigator.clipboard.writeText(text);
      if (type === 'email') {
        setCopiedEmail(true);
        setTimeout(() => setCopiedEmail(false), 2000);
      } else {
        setCopiedPhone(true);
        setTimeout(() => setCopiedPhone(false), 2000);
      }
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };


  const triggerNotification = () => {
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 3000);
  };


  const [isHidden, setIsHidden] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  
  useEffect(() => {
    const controlHeader = () => {
      const currentScrollY = window.scrollY;
  
      if (currentScrollY > lastScrollY && currentScrollY > 100) {
        setIsHidden(true);
      } else {
        setIsHidden(false);
      }
      
      setLastScrollY(currentScrollY);
    };
  
    window.addEventListener('scroll', controlHeader);
    return () => window.removeEventListener('scroll', controlHeader);
  }, [lastScrollY]);


  useEffect(() => {
    // Get the hash from the URL (e.g., #property-management)
    const hash = window.location.hash;
    if (hash) {
      // Remove the # symbol
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        }, 400);
      }
    }
  }, []);

  // Handle scroll events
  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 150);
      
      // Update active section based on scroll position
      const sections = document.querySelectorAll('section[id]');
      const scrollY = window.scrollY;

      sections.forEach(section => {
        const sectionTop = section.offsetTop - 100;
        const sectionHeight = section.offsetHeight;
        const sectionId = section.getAttribute('id');

        if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
          setActiveSection(sectionId);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  
  const scrollToDetailedService = (serviceId) => {
    const element = document.getElementById(serviceId);
    if (element) {
      element.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
      setSelectedService(serviceId);
      setOpenDropdown(false); // Close dropdown after selection
    }
  };
  
  const location = useLocation();


  const services = [
    {
      icon: Home,
      title: "Residential Lettings",
      desc: "Complete management services for your property portfolio",
      features: [
        "24/7 Property Support",
        "Tenant Management",
        "Regular Property Inspections",
        "Maintenance Coordination",
        "Financial Reporting",
        "Online Portal Access"
      ]
    },
    {
      icon: Key,
      title: "Short Term Luxury Lettings",
      desc: "Professional letting services for landlords and tenants",
      features: [
        "Tenant Screening",
        "Property Marketing",
        "Viewings Management",
        "Contract Preparation",
        "Deposit Protection",
        "Rent Collection"
      ]
    },
    {
      icon: Users,
      title: "Portfolio Management",
      desc: "Specialised services for residential blocks and estates",
      features: [
        "Service Charge Management",
        "Contractor Coordination",
        "Resident Communication",
        "",
        "Major Works Planning",
        "Health & Safety Compliance"
      ]
    },
    {
      icon: ClipboardCheck,
      title: "Maintenance",
      desc: "Regular maintenance and emergency repairs",
      parentSection: "property-management",
      features: [
        "24/7 Emergency Response",
        "Planned Maintenance",
        "Contractor Management",
        "Quality Control",
        "Cost Management",
        "Maintenance Reporting"
      ]
    },
    {
      icon: FileSpreadsheet,
      title: "Planning",
      desc: "Expert planning application and approval services",
      features: [
        "Planning Applications",
        "Feasibility Studies",
        "Local Authority Liaison",
        "Design & Access Statements",
        "Planning Appeals",
        "Conservation Area Expertise"
      ]
    },
    {
      icon: Building,
      title: "Design and Build",
      desc: "Comprehensive design and construction solutions",
      features: [
        "Architectural Design",
        "Interior Planning",
        "Building Regulations",
        "Construction Management",
        "Quality Assurance",
        "Project Timeline Control"
      ]
    },
    {
      icon: Combine,
      title: "Project Coordination",
      desc: "Seamless coordination of construction projects",
      parentSection: "development",
      features: [
        "Stakeholder Management",
        "Resource Allocation",
        "Timeline Optimisation",
        "Risk Assessment",
        "Progress Monitoring",
        "Budget Control"
      ]
    },
    {
      icon: HardHat,
      title: "Construction Delivery",
      desc: "End-to-end construction project management",
      parentSection: "development",
      features: [
        "Site Management",
        "Safety Compliance",
        "Quality Control",
        "Schedule Management",
        "Contractor Supervision",
        "Project Documentation"
      ]
    }
  ];

    // Navigation items with sections
    const navItems = [
      { name: "Services", section: "our-services" },
      { name: "Why Us", section: "whyus" },
      { name: "Details", section: "detail-services" },
      { name: "Contact", section: "contact" }
    ];
    const scrollToElementWithOffset = (elementId, offset = 240) => {
      const element = document.getElementById(elementId);
      if (element) {
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - offset;
        
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    };
  
    // Update the existing scrollToSection function
    const scrollToSection = (sectionId) => {
      scrollToElementWithOffset(sectionId);
      setIsMenuOpen(false);
    };


    const residentialProperties = [
      {
        image: "glasshouse/glasshouse.jpg",
        title: "The Glasshouse",
        specs: "4 bed • 2 bath",
      },
      {
        image: "toynbee/toynbee.jpg",
        title: "Toynbee House",
        specs: "4 bed • 3 bath",
      }
    ];
  
    const shortTermProperties = [
      {
        image: "madinah-mansions/madinah-mansions.jpg",
        title: "Madinah Mansions",
        specs: "3 bed • 2 bath",
      },
      {
        image: "haldane/haldane.jpg",
        title: "Haldane House",
        specs: "1 bed • 1 bath",
      }
    ];

    const PortfolioManagementProperties = [
      {
        image: "westbrookwhitfield.jpg",
        title: "The Wilcox | Nine Elms",
        specs: "22 apartments • 29,913 sq ft",
        url: "https://westbrookwhitfield.com/project/the-wilcox/"
      },
      {
        image: "historic-lux.webp",
        title: "Executive Suite - Westminster",
        specs: "3 bed • 2 bath",
      }
    ];

    const PlanningProperties = [
      {
        image: "historic-lux.webp",
        title: "Luxury Apartment - Chelsea",
        specs: "3 bed • 2 bath",
      },
      {
        image: "historic-lux.webp",
        title: "Executive Suite - Westminster",
        specs: "3 bed • 2 bath",
      }
    ];

    const DesignBuildProperties = [
      {
        image: "al-hamra-mansions/al-hamra-mansions.jpg",
        title: "Al Hamra Mansions",
        specs: "20 apartments • 29,913 sq ft",
      },
      {
        image: "656f8db209ba858703107920_DAS 2-p-800.jpg",
        title: "Tooting High Street",
        specs: "29 residential properties • 50,000 sq ft • 2025 complete",
        url: "https://www.t2sarchitecture.com//projects/tooting-high-street"
      }
    ];

    /* const CoordinationProperties = [
      {
        image: "pg30011-o-7c704acc-0ae5-d83f-6df5-f1144684dcac.avif",
        title: "Luxury Apartment - Chelsea",
        specs: "3 bed • 2 bath",
        url: "https://www.example.com/rental1"
      },
      {
        image: "HeaderWithBackground__0000_Auriens-Chelsea-Show-Apartment-Sitting-Room-Photo-Credit-Sim-Canetty-Clarke_Compressed.webp",
        title: "Executive Suite - Westminster",
        specs: "3 bed • 2 bath",
        url: "images (2).jpeg"
      }
    ];

    const ConstructionProperties = [
      {
        image: "pg30011-o-7c704acc-0ae5-d83f-6df5-f1144684dcac.avif",
        title: "Luxury Apartment - Chelsea",
        specs: "3 bed • 2 bath",
        url: "https://www.example.com/rental1"
      },
      {
        image: "HeaderWithBackground__0000_Auriens-Chelsea-Show-Apartment-Sitting-Room-Photo-Credit-Sim-Canetty-Clarke_Compressed.webp",
        title: "Executive Suite - Westminster",
        specs: "3 bed • 2 bath",
        url: "images (2).jpeg"
      }
    ];


    const MaintenanceProperties = [
      {
        image: "pg30011-o-7c704acc-0ae5-d83f-6df5-f1144684dcac.avif",
        title: "Luxury Apartment - Chelsea",
        specs: "3 bed • 2 bath",
        url: "https://www.example.com/rental1"
      },
      {
        image: "HeaderWithBackground__0000_Auriens-Chelsea-Show-Apartment-Sitting-Room-Photo-Credit-Sim-Canetty-Clarke_Compressed.webp",
        title: "Executive Suite - Westminster",
        specs: "3 bed • 2 bath",
        url: "images (2).jpeg"
      }
    ]; */


    const PropertyCard = ({ property }) => (
      property.url ? (
        <a 
          href={property.url}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-start space-x-4 p-2 rounded-lg hover:bg-white/10 transition-colors duration-200 group"
        >
          <div className="w-24 h-16 rounded-lg flex-shrink-0 overflow-hidden group-hover:ring-2 ring-white/20 transition-all duration-200">
            <img
              src={`/${property.image}`} 
              alt={property.title}
              className="w-full h-full object-cover"
              onError={(e) => {
                e.target.onerror = null;
                e.target.parentElement.classList.add('bg-neutral-700');
              }}
            />
          </div>
          <div className="flex-grow">
            <h6 className="text-white font-medium group-hover:text-[#b2a5a1] transition-colors duration-200">
              {property.title}
            </h6>
            <p className="text-neutral-300 text-sm group-hover:text-white transition-colors duration-200">
              {property.specs}
            </p>
          </div>
          <ArrowRight className="w-4 h-4 text-white opacity-0 group-hover:opacity-100 transform -translate-x-2 group-hover:translate-x-0 transition-all duration-200" />
        </a>
      ) : (
        <Link 
          to={`/properties?property=${property.title}`}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-start space-x-4 p-2 rounded-lg hover:bg-white/10 transition-colors duration-200 group"
        >
          <div className="w-24 h-16 rounded-lg flex-shrink-0 overflow-hidden group-hover:ring-2 ring-white/20 transition-all duration-200">
            <img
              src={`/${property.image}`} 
              alt={property.title}
              className="w-full h-full object-cover"
              onError={(e) => {
                e.target.onerror = null;
                e.target.parentElement.classList.add('bg-neutral-700');
              }}
            />
          </div>
          <div className="flex-grow">
            <h6 className="text-white font-medium group-hover:text-[#b2a5a1] transition-colors duration-200">
              {property.title}
            </h6>
            <p className="text-neutral-300 text-sm group-hover:text-white transition-colors duration-200">
              {property.specs}
            </p>
          </div>
          <ArrowRight className="w-4 h-4 text-white opacity-0 group-hover:opacity-100 transform -translate-x-2 group-hover:translate-x-0 transition-all duration-200" />
        </Link>
      )
    );



  return (
    <div className="min-h-screen bg-neutral-50">
      {/* Notification */}
      <div className={`fixed top-4 right-4 transform transition-all duration-300 z-50 ${showNotification ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'}`}>
        <div className="bg-[#b2a5a1] text-white px-6 py-3 rounded-lg shadow-lg flex items-center">
          <span>Thanks for your interest! We'll be in touch soon.</span>
          <button onClick={() => setShowNotification(false)} className="ml-3 hover:text-neutral-200">
            <X size={18} />
          </button>
        </div>
      </div>

      {/* Scroll to top button */}
      <button
        onClick={scrollToTop}
        className={`fixed bottom-8 right-8 z-40 p-3 bg-[#b2a5a1] text-white rounded-full shadow-lg transition-all duration-300 hover:bg-[#8c7f7c] ${
          showScrollTop ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-16'
        }`}
      >
        <ChevronUp size={24} />
      </button>

      {/* Section Navigation items */}
      <div className="hidden lg:block fixed left-8 top-1/2 transform -translate-y-1/2 z-40">
        <div className="space-y-4">
          {navItems.map((item) => (
            <button
              key={item.section}
              onClick={() => scrollToSection(item.section)}
              className={`flex items-center space-x-2 text-sm ${
                activeSection === item.section ? 'text-black font-medium' : 'text-neutral-600'
              } hover:text-black transition-colors duration-200`}
            >
              <div className={`h-0.5 transition-all duration-200 ${
                activeSection === item.section ? 'w-12 bg-white' : 'w-8 bg-white/60 group-hover:w-12 group-hover:bg-white'
              }`} />
              <span className="font-medium">{item.name}</span>
            </button>
          ))}
        </div>
      </div>


{/* Header */}
<header 
  className={`
    bg-white border-b border-[#eae7e6] sticky top-0 z-40 
    transition-transform duration-300 
    ${isHidden ? '-translate-y-full' : 'translate-y-0'}
  `}
>    
  <div className="max-w-7xl mx-auto px-4 sm:px-6">
    <div className="flex justify-between items-center h-20">
      {/* Logo */}
      <div className="w-64"> 
        <Link to="/" className="flex items-center group cursor-pointer">
          <img 
            src="/header-logo-new.png" 
            alt="Nova Asset Management" 
            className="h-12 md:h-14 w-auto object-contain md:object-fill transform group-hover:scale-105 transition-transform duration-200"
          />
        </Link>
      </div>

      {/* Centered Desktop Navigation */}
      <nav className="hidden md:flex flex-1 justify-center">
        <div className="flex space-x-12">
          {["Home", "About", "Services", "Properties", "Contact"].map((item) => (
            <div
              key={item}
              className="relative group"
              onMouseEnter={() => {
                setHoveredLink(item);
                if (item === "Services") setOpenDropdown(true);
              }}
              onMouseLeave={() => {
                setHoveredLink(null);
                if (item === "Services") setOpenDropdown(false);
              }}
            >
              <Link
                to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
                className={`
                  relative text-[#8c7f7c] hover:text-[#b2a5a1] font-medium 
                  transition-colors duration-200 py-2 flex items-center 
                  ${(item === "Home" && location.pathname === "/") || 
                    (item !== "Home" && location.pathname === `/${item.toLowerCase()}`)
                      ? "text-[#b2a5a1]" 
                      : ""
                  }
                `}
              >
                {item}
                {item === "Services" && (
                  <ChevronDown className="ml-1 w-4 h-4" />
                )}
                <span 
                  className={`
                    absolute bottom-0 left-0 w-full h-0.5 bg-[#b2a5a1] 
                    transform origin-left transition-transform duration-200 
                    ${((item === "Home" && location.pathname === "/") || 
                       (item !== "Home" && location.pathname === `/${item.toLowerCase()}`) ||
                       hoveredLink === item) ? 'scale-x-100' : 'scale-x-0'
                    }
                  `}
                />
              </Link>

              {/* Services Dropdown */}
              {item === "Services" && (
                <div 
                  className={`
                    absolute left-1/2 transform -translate-x-1/2 mt-2 w-72 
                    bg-white rounded-lg shadow-lg border border-[#eae7e6] 
                    transition-all duration-200 
                    ${openDropdown ? 'opacity-100 visible translate-y-0' : 'opacity-0 invisible -translate-y-2'}
                  `}
                >
                  <div className="p-4 space-y-3">
                    {[
                      { 
                        icon: Home, 
                        title: "Property Management",
                        sectionId: "property-management", 
                        subItems: [
                          { name: "Residential Lettings", id: "residential-lettings" },
                          { name: "Short Term Luxury Lettings", id: "short-term-luxury-lettings" },
                          { name: "Portfolio Management", id: "portfolio-management" }
                        ] 
                      },
                      { 
                        icon: Key, 
                        title: "Planning, Design & Build",
                        sectionId: "development", 
                        subItems: [
                          { name: "Planning", id: "planning" },
                          { name: "Design and Build", id: "design-and-build" }
                        ] 
                      }
                    ].map((service, index) => (
                      <div
                        key={index}
                        className="relative group/item"
                        onMouseEnter={() => setHoveredLink(service.title)}
                        onMouseLeave={() => setHoveredLink(null)}
                      >
                        <div 
                          className="flex items-center justify-between px-3 py-2 rounded-md hover:bg-[#f7f5f5] text-[#8c7f7c] hover:text-[#b2a5a1] transition-colors duration-200 cursor-pointer"
                          onClick={() => {
                            const element = document.getElementById(service.sectionId);
                            if (element) {
                              element.scrollIntoView({ 
                                behavior: 'smooth',
                                block: 'start'
                              });
                              setOpenDropdown(false);
                              setHoveredLink(null);
                            }
                          }}
                        >
                          <div className="flex items-center space-x-3 min-w-0">
                            <service.icon className="w-5 h-5 flex-shrink-0" />
                            <span className="font-medium whitespace-nowrap">{service.title}</span>
                          </div>
                          <ChevronDown className="w-4 h-4 flex-shrink-0 ml-3" />
                        </div>

                        {/* Nested Dropdown */}
                        <div 
                          className={`
                            absolute left-full top-0 w-56 bg-white rounded-lg 
                            shadow-lg border border-[#eae7e6] ml-2 
                            transition-all duration-200
                            ${hoveredLink === service.title ? 'opacity-100 visible translate-x-0' : 'opacity-0 invisible -translate-x-2'}
                          `}
                        >
                          <div className="py-2">
                            {service.subItems.map((subItem, subIndex) => (
                              <button
                                key={subIndex}
                                onClick={() => {
                                  const element = document.getElementById(subItem.id);
                                  if (element) {
                                    element.scrollIntoView({ 
                                      behavior: 'smooth',
                                      block: 'start'
                                    });
                                    setOpenDropdown(false);
                                    setHoveredLink(null);
                                  }
                                }}
                                className="w-full text-left px-4 py-2 text-[#8c7f7c] hover:text-[#b2a5a1] hover:bg-[#f7f5f5] text-sm transition-colors duration-200"
                              >
                                {subItem.name}
                              </button>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </nav>

      {/* Right Section - Contact Icons and Mobile Menu */}
      <div className="w-64 flex items-center justify-end">
        {/* Contact Icons - Desktop Only */}
        <div className="hidden md:flex items-center gap-4 mr-4">
          {/* Email Icon with Tooltip */}
          <div className="relative">
            <div className="p-2 group">
              <Mail 
                size={20} 
                className="text-[#8c7f7c] group-hover:text-[#b2a5a1] cursor-pointer"
              />
              {/* Email Tooltip */}
              <div className="absolute right-0 mt-2 w-auto min-w-max invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-200 bg-white border border-[#eae7e6] rounded-lg shadow-lg p-3 z-50">
                <div className="flex items-center space-x-2 whitespace-nowrap">
                  <span className="text-[#8c7f7c]">{contactInfo.email}</span>
                  <button 
                    onClick={(e) => {
                      e.stopPropagation();
                      copyToClipboard(contactInfo.email, 'email');
                    }}
                    className="text-[#8c7f7c] hover:text-[#b2a5a1] transition-colors duration-200 ml-2"
                  >
                    {copiedEmail ? <Check size={16} /> : <Copy size={16} />}
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Phone Icon with Tooltip */}
          <div className="relative">
            <div className="p-2 group">
              <Phone 
                size={20} 
                className="text-[#8c7f7c] group-hover:text-[#b2a5a1] cursor-pointer"
              />
              {/* Phone Tooltip */}
              <div className="absolute right-0 mt-2 w-auto min-w-max invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-200 bg-white border border-[#eae7e6] rounded-lg shadow-lg p-3 z-50">
                <div className="flex items-center space-x-2 whitespace-nowrap">
                  <span className="text-[#8c7f7c]">{contactInfo.phone}</span>
                  <button 
                    onClick={(e) => {
                      e.stopPropagation();
                      copyToClipboard(contactInfo.phone, 'phone');
                    }}
                    className="text-[#8c7f7c] hover:text-[#b2a5a1] transition-colors duration-200 ml-2"
                  >
                    {copiedPhone ? <Check size={16} /> : <Copy size={16} />}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile menu button */}
        <button 
          className="md:hidden relative group p-2"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <div className="flex flex-col space-y-1.5">
            <span className={`block w-6 h-0.5 bg-[#8c7f7c] transform transition-all duration-200 ${isMenuOpen ? 'rotate-45 translate-y-2' : ''}`}></span>
            <span className={`block w-6 h-0.5 bg-[#8c7f7c] transition-all duration-200 ${isMenuOpen ? 'opacity-0' : ''}`}></span>
            <span className={`block w-6 h-0.5 bg-[#8c7f7c] transform transition-all duration-200 ${isMenuOpen ? '-rotate-45 -translate-y-2' : ''}`}></span>
          </div>
        </button>
      </div>
    </div>
  </div>

  {/* Mobile Navigation */}
  <div className={`md:hidden bg-white overflow-hidden transition-all duration-300 ${isMenuOpen ? 'max-h-screen' : 'max-h-0'}`}>
    <div className="px-4 py-3 space-y-2">
      {["Home", "About", "Services", "Properties", "Contact"].map((item) => (
        <div key={item}>
          <Link
            to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
            className={`
              block px-4 py-3 text-center hover:text-[#b2a5a1] 
              hover:bg-[#f7f5f5] rounded-lg transition-all duration-200 
              ${(item === "Home" && location.pathname === "/") || 
                (item !== "Home" && location.pathname === `/${item.toLowerCase()}`)
                  ? "text-[#b2a5a1] bg-[#f7f5f5]"
                  : "text-[#8c7f7c]"
              }
            `}
            onClick={() => {
              if (item === "Services") {
                setOpenDropdown(!openDropdown);
              } else {
                setIsMenuOpen(false);
              }
            }}
          >
            <div className="flex items-center justify-center">
              {item}
              {item === "Services" && (
                <ChevronDown className={`ml-1 w-4 h-4 transform transition-transform duration-200 ${openDropdown ? 'rotate-180' : ''}`} />
              )}
            </div>
          </Link>
          
          {/* Mobile Services Dropdown */}
          {item === "Services" && (
            <div className={`overflow-hidden transition-all duration-200 ${openDropdown ? 'max-h-64' : 'max-h-0'}`}>
              <div className="py-2 px-4 space-y-2">
                {[
                  { icon: Home, title: "Property Management" },
                  { icon: Key, title: "Lettings" },
                  { icon: Users, title: "Portfolio Management" },
                  { icon: ClipboardCheck, title: "Maintenance" }
                ].map((service, index) => (
                  <Link
                    key={index}
                    to="/services"
                    className="flex items-center space-x-3 px-4 py-2 rounded-md hover:bg-[#f7f5f5] text-[#8c7f7c] hover:text-[#b2a5a1] transition-colors duration-200"
                    onClick={() => {
                      setIsMenuOpen(false);
                      setOpenDropdown(false);
                    }}
                  >
                    <service.icon className="w-5 h-5" />
                    <span className="font-medium">{service.title}</span>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  </div>
</header>


{/* Main Content */}
<div id="our-services" className="py-24 bg-gradient-to-br from-[#8c7f7c]/70 to-[#6a5f5c]/70">
  <div className="max-w-7xl mx-auto px-4 sm:px-6">
    {/* Title Section */}
    <div className="text-center mb-16">
      <h1 className="text-4xl font-bold text-white sm:text-5xl">
        Our Services
      </h1>
      <p className="mt-4 text-xl text-neutral-200 max-w-2xl mx-auto">
        Comprehensive property management solutions tailored to your needs
      </p>
    </div>

    {/* Services Grid */}
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
      {services.map((service, i) => (
        <div
          key={i}
          className="relative group cursor-pointer"
          onMouseEnter={() => setActiveService(i)}
          onMouseLeave={() => setActiveService(null)}
        >
          {/* Border glow effect */}
          <div className="absolute -inset-0.5 bg-white opacity-0 group-hover:opacity-30 rounded-lg blur transition duration-300">
          </div>

          {/* Card content */}
          <div className="relative bg-white/10 backdrop-blur-lg p-6 rounded-lg shadow-lg border border-white/20 transition-all duration-300 h-full">
            {/* Icon */}
            <div className="flex justify-center">
              <service.icon className="h-12 w-12 text-white transform group-hover:scale-110 transition-transform duration-300" />
            </div>

            {/* Title and Description */}
            <h3 className="mt-4 text-lg font-semibold text-white text-center">
              {service.title}
            </h3>
            <p className="mt-2 text-neutral-200 text-center">
              {service.desc}
            </p>

            {/* Features List */}
            <div 
              className={`
                mt-4 space-y-2 transition-all duration-300 overflow-hidden
                ${activeService === i ? 'opacity-100 max-h-96' : 'opacity-0 max-h-0'}
              `}
            >
              {service.features.map((feature, j) => (
                <div key={j} className="flex items-center text-sm text-neutral-200">
                  <Star className="h-4 w-4 text-white mr-2" />
                  {feature}
                </div>
              ))}

              {/* Call to Action Button */}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  const elementId = service.parentSection || service.title.toLowerCase().replace(/\s+/g, '-');
                  scrollToElementWithOffset(elementId);
                }}
                className="mt-4 w-full px-4 py-2 bg-white text-[#8c7f7c] rounded-lg font-medium hover:bg-neutral-100 transition-colors duration-200 flex items-center justify-center group"
              >
                Learn More
                <ArrowRight className="ml-2 transform group-hover:translate-x-1 transition-transform" />
              </button>
        </div>
    </div>
  </div>
  ))}
</div>

{/* Why Choose Us Section */}
<div id="whyus" className="mt-24 bg-white/10 backdrop-blur-lg rounded-lg p-8 border border-white/20">
  <h2 className="text-3xl font-bold text-white text-center mb-12">
    Why Choose Nova Asset Management
  </h2>

  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
    {[
      {
        icon: Shield,
        title: "Professional Excellence",
        desc: "Certified property management experts with years of experience"
      },
      {
        icon: Wrench,
        title: "Responsive Service",
        desc: "24/7 support and swift resolution of property issues"
      },
      {
        icon: PoundSterling,
        title: "Cost Effective",
        desc: "Transparent pricing and optimised property maintenance"
      },
      {
        icon: Users,
        title: "Client Focused",
        desc: "Personalised service tailored to your specific needs"
      }
    ].map((item, i) => (
      <div key={i} className="text-center p-6">
        <div className="flex justify-center mb-4">
          <div className="p-3 bg-white/10 rounded-lg">
            <item.icon className="h-8 w-8 text-white" />
          </div>
        </div>
        <h3 className="text-lg font-semibold text-white mb-2">
          {item.title}
        </h3>
        <p className="text-neutral-200">
          {item.desc}
        </p>
      </div>
    ))}
  </div>
</div>

{/* Detailed Services Section */}
<div id="detail-services" className="mt-24 space-y-12">
  <h2 className="text-3xl font-bold text-white text-center mb-12">Our Services in Detail</h2>
  
  {/* Property Management Section */}
  <div id="property-management" className="bg-white/10 backdrop-blur-lg rounded-lg border border-white/20 overflow-hidden">
    <div className="p-8">
      {/* Main Section Header */}
      <h3 className="text-3xl font-bold text-white mb-8">Property Management</h3>
      <div className="space-y-12">

        {/* Residential Lettings */}
        <div id="residential-lettings" className="space-y-6">
          {/* Sub-section Header */}
          <h4 className="text-2xl font-semibold text-white flex items-center gap-3">
            <Home className="h-6 w-6" />
            Residential Lettings
          </h4>
          
          {/* Description Text - now as a single line */}
          <p className="text-neutral-200">
            Our comprehensive residential letting service ensures your property is well-maintained and profitable. We handle every aspect of property management to maximise your investment's potential.
          </p>
          
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <ul className="space-y-2">
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Tenant sourcing and rigorous vetting</span>
              </li>
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Rent collection and financial management</span>
              </li>
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Regular property inspections</span>
              </li>
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>24/7 maintenance coordination</span>
              </li>
            </ul>
            <div className="bg-white/5 rounded-lg p-6">
              <h5 className="text-lg font-medium text-white mb-4">Featured Properties</h5>
              <div className="space-y-4">
                {residentialProperties.map((property, index) => (
                  <PropertyCard key={index} property={property} />
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Short Term Luxury Lettings */}
        <div id="short-term-luxury-lettings" className="space-y-6 pt-8 border-t border-white/10">
          {/* Sub-section Header */}
          <h4 className="text-2xl font-semibold text-white flex items-center gap-3">
            <Key className="h-6 w-6" />
            Short Term Luxury Lettings
          </h4>
          
          {/* Description Text */}
          <p className="text-neutral-200">
            Maximise your property's potential with our premium short-term letting service. We specialise in high-end properties and ensure maximum returns while maintaining property standards.
          </p>
          
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <ul className="space-y-2">
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Professional photography and marketing</span>
              </li>
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Dynamic pricing strategy</span>
              </li>
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Guest screening and communication</span>
              </li>
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Luxury cleaning and maintenance</span>
              </li>
            </ul>
            <div className="bg-white/5 rounded-lg p-6">
              <h5 className="text-lg font-medium text-white mb-4">Featured Properties</h5>
              <div className="space-y-4">
                {shortTermProperties.map((property, index) => (
                  <PropertyCard key={index} property={property} />
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Portfolio Management */}
        <div id="portfolio-management" className="space-y-6 pt-8 border-t border-white/10">
          {/* Sub-section Header */}
          <h4 className="text-2xl font-semibold text-white flex items-center gap-3">
            <Building2 className="h-6 w-6" />
            Portfolio Management
          </h4>
          
          {/* Description Text */}
          <p className="text-neutral-200">
            Expert management of residential blocks and estates. We provide comprehensive services to ensure smooth operation and maintenance of multi-unit properties.
          </p>
          
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <ul className="space-y-2">
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Service charge management</span>
              </li>
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Building maintenance programs</span>
              </li>
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Health & safety compliance</span>
              </li>
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Leaseholder communication</span>
              </li>
            </ul>
            <div className="bg-white/5 rounded-lg p-6">
              <h5 className="text-lg font-medium text-white mb-4">Featured Properties</h5>
              <div className="space-y-4">
                {PortfolioManagementProperties.map((property, index) => (
                  <PropertyCard key={index} property={property} />
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Maintenance 
        <div id="maintenence" className="space-y-6">
          {/* Sub-section Header 
          <h4 className="text-2xl font-semibold text-white flex items-center gap-3">
            <ClipboardCheck className="h-6 w-6" />
            Property Maintenance
          </h4>
          
          {/* Description Text 
          <p className="text-neutral-200">
            Comprehensive maintenance services ensuring your property remains in optimal condition. Round-the-clock emergency maintenance support for all your property needs:
          </p>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <ul className="space-y-2">
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>24/7 Emergency Response</span>
              </li>
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Planned Maintenance Programs</span>
              </li>
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Preventive Maintenance</span>
              </li>
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Quality Control Inspections</span>
              </li>
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Rapid Response Teams</span>
              </li>
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Emergency Repairs</span>
              </li>
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Incident Reporting & Analysis</span>
              </li>
            </ul>
            <div className="bg-white/5 rounded-lg p-6">
              <h5 className="text-lg font-medium text-white mb-4">Featured Properties</h5>
              <div className="space-y-4">
                {MaintenanceProperties.map((property, index) => (
                  <PropertyCard key={index} property={property} />
                ))}
              </div>
            </div>
          </div>
        </div> */}

      </div>
    </div>
  </div>

  {/* Development Section */}
  <div id="development" className="bg-white/10 backdrop-blur-lg rounded-lg border border-white/20 overflow-hidden">
    <div className="p-8">
      {/* Main Section Header */}
      <h3 className="text-3xl font-bold text-white mb-8">Planning, Design and Build</h3>
      <div className="space-y-12">
        
        {/* Planning */}
        <div id="planning" className="space-y-6 pt-8 border-t border-white/10">
          {/* Sub-section Header */}
          <h4 className="text-2xl font-semibold text-white flex items-center gap-3">
            <FileSpreadsheet className="h-6 w-6" />
            Planning
          </h4>
          
          {/* Description Text */}
          <p className="text-neutral-200">
            Expert planning application and approval services, including:
          </p>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <ul className="space-y-2">
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Planning application management</span>
              </li>
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Feasibility studies and assessments</span>
              </li>
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Local authority liaison</span>
              </li>
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Appeals and documentation</span>
              </li>
            </ul>
            <div className="bg-white/5 rounded-lg p-6">
              <h5 className="text-lg font-medium text-white mb-4">Featured Properties</h5>
              <div className="space-y-4">
                {PlanningProperties.map((property, index) => (
                  <PropertyCard key={index} property={property} />
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Design and Build */}
        <div id="design-and-build" className="space-y-6 pt-8 border-t border-white/10">
          {/* Sub-section Header */}
          <h4 className="text-2xl font-semibold text-white flex items-center gap-3">
            <Building className="h-6 w-6" />
            Design and Build
          </h4>
          
          {/* Description Text */}
          <p className="text-neutral-200">
            Complete end-to-end property development solutions. Our services include:
          </p>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <ul className="space-y-2">
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Architectural design and planning</span>
              </li>
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Interior design consultation</span>
              </li>
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Construction management</span>
              </li>
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Quality assurance and control</span>
              </li>
            </ul>
            <div className="bg-white/5 rounded-lg p-6">
              <h5 className="text-lg font-medium text-white mb-4">Featured Properties</h5>
              <div className="space-y-4">
                {DesignBuildProperties.map((property, index) => (
                  <PropertyCard key={index} property={property} />
                ))}
              </div>
            </div>
          </div>
        </div>
      
      </div>
    </div>
  </div>

  {/* Project Management Section 
  <div id="project-management" className="bg-white/10 backdrop-blur-lg rounded-lg border border-white/20 overflow-hidden">
    <div className="p-8">
      {/* Main Section Header 
      <h3 className="text-3xl font-bold text-white mb-8">Project Management</h3>
      <div className="space-y-12">

        {/* Project Coordination 
        <div id="project-coordination" className="space-y-6">
          {/* Sub-section Header 
          <h4 className="text-2xl font-semibold text-white flex items-center gap-3">
            <Combine className="h-6 w-6" />
            Project Coordination
          </h4>
          
          {/* Description Text 
          <p className="text-neutral-200">
            Seamless coordination of construction and development projects:
          </p>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <ul className="space-y-2">
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Stakeholder coordination</span>
              </li>
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Timeline and resource management</span>
              </li>
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Risk assessment and mitigation</span>
              </li>
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Progress monitoring and reporting</span>
              </li>
            </ul>
            <div className="bg-white/5 rounded-lg p-6">
              <h5 className="text-lg font-medium text-white mb-4">Featured Properties</h5>
              <div className="space-y-4">
                {CoordinationProperties.map((property, index) => (
                  <PropertyCard key={index} property={property} />
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Construction Delivery 
        <div id="construction-delivery" className="space-y-6 pt-8 border-t border-white/10">
          {/* Sub-section Header 
          <h4 className="text-2xl font-semibold text-white flex items-center gap-3">
            <HardHat className="h-6 w-6" />
            Construction Delivery
          </h4>
          
          {/* Description Text 
          <p className="text-neutral-200">
            Professional management of construction projects, featuring:
          </p>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <ul className="space-y-2">
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Site management and supervision</span>
              </li>
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Health and safety compliance</span>
              </li>
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Quality control processes</span>
              </li>
              <li className="flex items-start space-x-2 text-neutral-200">
                <Star className="h-4 w-4 text-white mt-1 flex-shrink-0" />
                <span>Project documentation management</span>
              </li>
            </ul>
            <div className="bg-white/5 rounded-lg p-6">
              <h5 className="text-lg font-medium text-white mb-4">Featured Properties</h5>
              <div className="space-y-4">
                {ConstructionProperties.map((property, index) => (
                  <PropertyCard key={index} property={property} />
                ))}
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div> */}

</div>



          {/* CTA Section */}
          <div id="contact" className="mt-24 text-center">
            <h2 className="text-3xl font-bold text-white mb-4">Ready to Get Started?</h2>
            <p className="text-xl text-neutral-200 mb-8">
              Contact us today to discuss your property management needs
            </p>
            <Link
              to="/contact"
              className="inline-flex items-center px-8 py-3 bg-white text-[#8c7f7c] rounded-lg font-medium hover:bg-neutral-100 transition-colors duration-200 group"
            >
              Contact Us
              <ArrowRight className="ml-2 transform group-hover:translate-x-1 transition-transform" />
            </Link>
          </div>

        </div>
      </div>


{/* Contact */}
<div className="bg-[#2c2827]">
  <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
    <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
      {[
        {
          icon: Phone,
          text: "0749 503 1435",
          action: () => window.location.href = "tel:+442012345678"
        },
        {
          icon: Mail,
          text: "info@novalimited.co.uk",
          action: () => window.location.href = "mailto:info@novalimited.co.uk"
        },
        {
          icon: MapPin,
          text: "83 Wimbledon Park Side, SW19 5LP",
          action: () => window.open("https://maps.app.goo.gl/6fTsgFR4MS9x7PAt8", "_blank")
        }
      ].map((item, i) => (
        <button
          key={i}
          onClick={item.action}
          className="flex items-center p-4 bg-[#3d3533] rounded-lg shadow-sm border border-[#4d4341] hover:border-[#b2a5a1] hover:shadow-md transition-all duration-200 group w-full"
        >
          <item.icon className="h-6 w-6 text-[#b2a5a1] group-hover:scale-110 transition-transform" />
          <span className="ml-3 text-[#968a87] group-hover:text-[#b2a5a1] transition-colors duration-200">
            {item.text}
          </span>
        </button>
      ))}
    </div>
  </div>
</div>

{/* Footer */}
<footer className="bg-[#2c2827] border-t border-[#3d3533]">
  <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
    <div className="grid grid-cols-2 gap-8 md:grid-cols-4">
      {[
        { 
          title: "Company", 
          links: [
            { name: "Home", path: "/" },
            { name: "About", path: "/about" },
            { name: "Properties", path: "/properties" },
            { name: "Contact", path: "/contact" }
          ]
        },
        { 
          title: "Services", 
          links: [
            { name: "Property Management", sectionId: "property-management"},
            { name: "Planning, Design & Build", sectionId: "development" },
          ]
        },
        { 
          title: "Legal", 
          links: [
            { name: "Privacy", path: "/privacy", type: "pdf" },
            { name: "Data Protection", path: "/Registration Certificate.pdf", type: "pdf" },
            { name: "Terms", path: "/terms" }
          ]
        },
        { 
          title: "Social", 
          links: [
            { name: "LinkedIn", path: "https://linkedin.com" },
            { name: "Twitter", path: "https://twitter.com" },
            { name: "Facebook", path: "https://facebook.com" }
          ]
        }
      ].map((section, i) => (
        <div key={i}>
          <h3 className="text-sm font-semibold text-[#b2a5a1] tracking-wider uppercase">
            {section.title}
          </h3>
          <ul className="mt-4 space-y-4">
            {section.links.map((link, j) => (
              <li key={j}>
                {section.title === "Social" ? (
                  <a 
                    href={link.path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-base text-[#968a87] hover:text-[#b2a5a1] transition-colors duration-200 flex items-center group"
                  >
                    <span>{link.name}</span>
                    <ArrowRight className="ml-2 opacity-0 group-hover:opacity-100 transform -translate-x-2 group-hover:translate-x-0 transition-all duration-200 text-[#b2a5a1]" size={14} />
                  </a>
                ) : section.title === "Services" ? (
                  <button
                    onClick={() => {
                      const element = document.getElementById(link.sectionId);
                      if (element) {
                        element.scrollIntoView({ 
                          behavior: 'smooth',
                          block: 'start'
                        });
                      }
                    }}
                    className="text-base text-[#968a87] hover:text-[#b2a5a1] transition-colors duration-200 flex items-center group"
                  >
                    <span>{link.name}</span>
                    <ArrowRight className="ml-2 opacity-0 group-hover:opacity-100 transform -translate-x-2 group-hover:translate-x-0 transition-all duration-200 text-[#b2a5a1]" size={14} />
                  </button>
                ) : link.type === "pdf" ? (
                  <a
                    href={link.path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-base text-[#968a87] hover:text-[#b2a5a1] transition-colors duration-200 flex items-center group"
                  >
                    <span>{link.name}</span>
                    <ArrowRight className="ml-2 opacity-0 group-hover:opacity-100 transform -translate-x-2 group-hover:translate-x-0 transition-all duration-200 text-[#b2a5a1]" size={14} />
                  </a>
                ) : (
                  <Link
                    to={link.path}
                    className="text-base text-[#968a87] hover:text-[#b2a5a1] transition-colors duration-200 flex items-center group"
                  >
                    <span>{link.name}</span>
                    <ArrowRight className="ml-2 opacity-0 group-hover:opacity-100 transform -translate-x-2 group-hover:translate-x-0 transition-all duration-200 text-[#b2a5a1]" size={14} />
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
    <div className="mt-8 border-t border-[#3d3533] pt-8">
      <p className="text-base text-[#968a87]">
        &copy; 2024 Nova Ltd. All rights reserved.
      </p>
    </div>
  </div>
</footer>

  </div>
  );
};

export default ServicesPage;